import { BsModalService } from './_modules/bs-modal/bs-modal.service';
import { throwError, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class BsInterceptor implements HttpInterceptor {
  constructor(private router: Router, private modalService: BsModalService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error, caught) => {
        console.log('Une erreur HTTP a eu lieu:');
        console.log('error: ', error);
        if (error.status === 401) {
          // this.modalService.setModalIsVisible$(true);
          this.router.navigate(['/login']);
          // return Observable.empty();
        }
        return throwError(error);
      })
    );
  }
}
