import { Observable } from 'rxjs';
import { BsModalService } from './../bs-modal.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bs-bs-modal',
  templateUrl: './bs-modal.component.html',
  styleUrls: ['./bs-modal.component.scss']
})
export class BsModalComponent implements OnInit {
  isVisible$: Observable<boolean>;
  header = '';
  footer = '';

  constructor(private modalService: BsModalService) {}

  ngOnInit() {
    this.isVisible$ = this.modalService.modalIsVisible$;
  }

  hideDialog() {
    this.modalService.setModalIsVisible$(false);
  }
  showDialog() {
    this.modalService.setModalIsVisible$(true);
  }
}
