/** ### Représente un élément du breadcrumb */
export class Breadcrumb {
  /** Le libellé affiché */
  label: string;
  /** La cible du lien */
  url: string;
  /** Si l'élément correspond à la vue active */
  current: boolean;

  /** ### Le constructeur */
  constructor(label: string, url: string, current?: boolean) {
    this.label = label;
    this.url = '/' + url;
    this.current = current ? current : false;
  }
}
