import { PipesModule } from './../pipes/pipes.module';
import { components } from './_components/index';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromComponents from './_components';
import { ThemesModule } from '../themes/themes.module';
import {BsCommonModule} from '../bs-common/bs-common.module';

@NgModule({
  imports: [CommonModule, PipesModule, ThemesModule, BsCommonModule],
  declarations: [...components],
  exports: [fromComponents.PlayerWidgetComponent]
})
export class PlayerWidgetModule {}
