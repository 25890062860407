import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { takeWhile } from 'rxjs/operators';
import { AppStateService } from '../../_services/appstate.service';
import { AppState } from '../../_services/appStateModels/app.state.model';
import { BreadcrumbService } from '../../_services/breadcrumb.service';
import { Breadcrumb } from '../../_classes/breadcrumb';
import { MenuElement } from '../../_classes/menu-element';
import { MenuService } from '../../_services/menu.service';

/** # Le menu principal de l'application */
@Component({
  selector: 'bs-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent implements OnInit, OnDestroy {
  /** Détermine l'état des subscribes */
  isAlive: boolean;
  /** L'état de l'application */
  appState: AppState;
  /** Le contenu du menu */
  menu: MenuElement[];
  /**
   * ### Le constructeur
   * @param router Le routeur
   * @param appStateService Le service de gestion de l'état de l'application
   */
  constructor(private router: Router, private appStateService: AppStateService, private menuService: MenuService) {}

  /**
   * ### Début du cycle de vie du composant
   * * Initialise les subscribes
   * * récupère l'état de l'application
   */
  ngOnInit() {
    this.isAlive = true;
    this.handleAppState();
    this.menu = this.menuService.getMenu();
  }

  /**
   * ### Fin du cycle de vie du composant
   * * Met fin aux subscribes
   */
  ngOnDestroy() {
    this.isAlive = false;
    this.menuService.dispose();
  }

  /**
   * ### Gère l'abonnment à l'état de l'application
   */
  handleAppState(): void {
    this.appStateService.appState$.pipe(takeWhile(() => this.isAlive)).subscribe(data => (this.appState = data));
  }
}
