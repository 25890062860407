import { Component, OnInit, Input } from '@angular/core';
import { style, animate, transition, trigger } from '@angular/animations';

/**
 * # affiche une image clickable et ouvre un popin avec l'image en plus grand
 */
@Component({
  selector: 'bs-lightbox',
  templateUrl: './lightbox.component.html',
  styleUrls: ['./lightbox.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        // :enter is alias to 'void => *'
        style({ opacity: 0 /* , display: 'none' */ }),
        animate('350ms ease-in', style({ opacity: 1 /* , display: 'block' */ }))
      ]),
      transition(':leave', [
        // :leave is alias to '* => void'
        animate('350ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('grow', [
      transition(':enter', [
        style({ transform: 'scale(0.0)' }),
        animate('350ms ease-in-out', style({ transform: 'scale(1.0)' }))
      ]),
      transition(':leave', [
        style({ transform: 'scale(1.0)' }),
        animate('350ms ease-in', style({ transform: 'scale(0.0)' }))
      ])
    ])
  ]
})
export class LightboxComponent implements OnInit {
  /** L'image à afficher */
  @Input()
  image: string;
  /** Le texte aleternatif de l'image */
  @Input()
  imageAlt: string;
  /** La classe css de l'image */
  @Input()
  classe = '';
  /** Si on affiche l'image en grand */
  zoom = false;

  @Input()
  contrast = false;

  constructor() {}

  ngOnInit() {}

  /**
   * ### Active/désactive la lightbox
   */
  toggleZoom(): void {
    this.zoom = !this.zoom;
  }
}
