import { AppStateService } from './_services/appstate.service';
import { HistoriqueModule } from './_modules/historique/historique.module';
import { NavSwitchModule } from './_modules/nav-switch/nav-switch.module';
import { PlayerWidgetModule } from './_modules/player-widget/player-widget.module';
import { PipesModule } from './_modules/pipes/pipes.module';
import { ThemesModule } from './_modules/themes/themes.module';
import { BsModalModule } from './_modules/bs-modal/bs-modal.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BsInterceptor } from './bs.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { SimpleNotificationsModule } from 'angular2-notifications';

import { AuthGuard } from './_guards/auth.guard';
import { AppRoutingModule } from './app-routing.module';
import { BsCommonModule } from './_modules/bs-common/bs-common.module';
import { NgModule } from '@angular/core';
import { VideogularPlayerModule } from './_modules/videogular-player/videogular-player.module';

import { AccueilComponent } from './_components/accueil/accueil.component';
import { AppComponent } from './app.component';
import { BreadcrumbsComponent } from './_components/breadcrumbs/breadcrumbs.component';
import { FooterComponent } from './_components/footer/footer.component';
import { HeaderComponent } from './_components/header/header.component';
import { LayoutComponent } from './_components/layout/layout.component';
import { ListeLivresComponent } from './_components/liste-livres/liste-livres.component';
import { LoginComponent } from './_components/login/login.component';
import { MainNavComponent } from './_components/main-nav/main-nav.component';

import { AuthenticationService } from './_services/authentication.service';
import { BibliothequeService } from './_services/bibliotheque.service';
import { BreadcrumbService } from './_services/breadcrumb.service';
import { LivresService } from './_services/livres.service';
import { MenuService } from './_services/menu.service';
import { UserService } from './_services/user.service';
import { RequestService } from './_services/request.service';

import { MobileMenuComponent } from './_components/mobile-menu/mobile-menu.component';

/* VIDEOGULAR */
import { VgCoreModule } from 'videogular2/core';
import { VgControlsModule } from 'videogular2/controls';
import { VgOverlayPlayModule } from 'videogular2/overlay-play';
import { VgBufferingModule } from 'videogular2/buffering';
import { VgStreamingModule } from 'videogular2/streaming';
import { CardsModule } from './_modules/cards/cards.module';
import { NgxXml2jsonModule } from 'ngx-xml2json';
import { PasswordResetComponent } from './_components/password-reset/password-reset.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    AccueilComponent,
    LayoutComponent,
    BreadcrumbsComponent,
    MainNavComponent,
    ListeLivresComponent,
    MobileMenuComponent,
    PasswordResetComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    BsModalModule,
    CardsModule,
    FormsModule,
    HistoriqueModule,
    ReactiveFormsModule,
    SimpleNotificationsModule.forRoot(),
    BsCommonModule,
    VideogularPlayerModule.forRoot(),
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    VgStreamingModule,
    HttpClientModule,
    ThemesModule,
    PipesModule,
    PlayerWidgetModule,
    NavSwitchModule,
    NgxXml2jsonModule,
    AppRoutingModule,
  ],
  providers: [
    AuthGuard,
    AppStateService,
    AuthenticationService,
    BibliothequeService,
    BreadcrumbService,
    LivresService,
    MenuService,
    RequestService,
    UserService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BsInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
