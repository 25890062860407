import {Component, OnInit, OnDestroy} from '@angular/core';
import {AppStateService} from '../../_services/appstate.service';
import {takeWhile} from 'rxjs/operators';

import {AppState} from '../../_services/appStateModels';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';

import {Router, ActivatedRoute} from '@angular/router';

import {AuthenticationService} from '../../_services/authentication.service';
import {environment} from 'environments/environment';

import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'bs-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit, OnDestroy {

  isAlive: boolean;
  appState: AppState;
  bodyClassName: string;
  loginForm: FormGroup;
  username: AbstractControl;
  returnUrl: string;
  hasError: boolean;
  errorMessage: string;

  version: string;

  constructor(
    private appstateService: AppStateService,
    private loginFB: FormBuilder,
    private authenticationService: AuthenticationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notificationsService: NotificationsService,
  ) {
    this.loginForm = this.loginFB.group({
      username: ['', [Validators.required]],
    });

    this.username = this.loginForm.controls['username'];
  }

  ngOnInit() {
    this.version = environment.version;
    this.hasError = false;
    this.isAlive = true;
    this.appstateService.resetLook();
    this.appstateService.appState$.pipe(takeWhile(() => this.isAlive)).subscribe(data => {
      this.updateAppState(data);
      this.setLook();
    });
    this.authenticationService.logout();
    this.activatedRoute.queryParamMap.pipe(takeWhile(() => this.isAlive)).subscribe(params => {
      this.returnUrl = params.get('returnUrl');
    });
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  updateAppState(data: AppState) {
    this.appState = data;
    this.setLook();
  }

  reset(): void {
    // this.notificationsService.success(
    //   '',
    //   'Un courriel vient de vous être envoyé à ' + this.username.value,
    //   {
    //     timeOut: 3000,
    //     showProgressBar: true,
    //     pauseOnHover: true,
    //     clickToClose: false,
    //     clickIconToClose: true
    //   }
    // );
    this.notificationsService.error(
      '',
      'Cette adresse courriel est inconnue, merci de vérifier votre saisie',
      {
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: false,
        clickIconToClose: true
      }
    );
    // this.authenticationService
    //   .reset(this.username.value)
    //   .pipe(takeWhile(() => this.isAlive))
    //   .subscribe(
    //     data => {
    //
    //       // this.saveDataToLocalStorage(data);
    //       // this.appstateService.setCurrentUser(data.user);
    //       // if (this.returnUrl) {
    //       //   this.router.navigate([this.returnUrl]);
    //       // } else {
    //       //   this.router.navigate(['/']);
    //       // }
    //     },
    //     error => {
    //       this.handleError(error)
    //     }
    //   );
  }

  setLook() {
    if (this.appState.user.userPrefs.dyslexia && this.appState.user.userPrefs.contrast) {
      this.bodyClassName = `cookiebanner dyslexia theme-${this.appState.bibPrefs.themeName}`;
    } else if (this.appState.user.userPrefs.dyslexia && !this.appState.user.userPrefs.contrast) {
      this.bodyClassName = `cookiebanner dyslexia theme-${this.appState.bibPrefs.themeName}`;
    } else if (!this.appState.user.userPrefs.dyslexia && this.appState.user.userPrefs.contrast) {
      this.bodyClassName = `cookiebanner theme-${this.appState.bibPrefs.themeName}`;
    } else {
      this.bodyClassName = `cookiebanner theme-${this.appState.bibPrefs.themeName}`;
    }

    document.body.className = this.bodyClassName;

    this.setFontSize();
  }

  setFontSize(): void {
    document.documentElement.setAttribute('style', `font-size:${this.appState.user.userPrefs.fontSize}%`);
  }

  handleError(error: any): void {
    this.hasError = true;
    if (error.status === 666) {
      // tslint:disable-next-line:quotemark
      this.errorMessage = "Vous n'êtes pas connecté ou vous avez été déconnecté. Veuillez vous connecter";
    } else if (error.status === 400 && error.error.message.indexOf('Invalid credentials') !== -1) {
      this.errorMessage = 'Identifiant ou mot de passe invalide';
    }
    console.log(error);
  }

  saveDataToLocalStorage(data: any) {
    localStorage.setItem('bibliostreamUser', JSON.stringify(data));
  }

}
