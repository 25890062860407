import { environment } from 'environments/environment';
import { AppState } from 'app/_services/appStateModels/app.state.model';
import { AppStateService } from '../../_services/appstate.service';
import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { takeWhile } from 'rxjs/operators';

/**
 * # Le pied de page de l'application
 */
@Component({
  selector: 'bs-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  @HostBinding('class.expand') expand = false;
  isAlive = true;

  version: string;
  /**
   * ### Le constructeur
   */
  constructor(private appStateSercice: AppStateService) {}

  /**
   * ### Le début du cycle de vie du composant
   */
  ngOnInit() {
    this.version = environment.version;
    this.appStateSercice.appState$.pipe(takeWhile(() => this.isAlive)).subscribe((state: AppState) => {
      if (state.uiDisplay.displayPlayerWidget && state.livreEnCours && state.livreEnCours.playing) {
        this.expand = true;
      } else {
        this.expand = false;
      }
    });
  }

  ngOnDestroy() {
    this.isAlive = false;
  }
}
