import { VideoGularService } from './../../../videogular-player/_services/videogular.service';
import { Component, OnInit, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'bs-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
  @HostBinding('class.hidden') isHidden = true;

  isDragging = false;

  constructor(public vgService: VideoGularService) {}

  ngOnInit() {
    this.vgService._currentStream$.subscribe(data => {
      if (data.title) {
        this.isHidden = false;
      }
    });
  }

  /**
   * ### Permet de changer la position de la tête de lecture avec un clique
   * @param event L'évènement de click
   */
  progJog(event: any) {
    let largeur = 0,
      posClick = 0,
      pourcentage = 0;

    largeur = event.target.parentElement.clientWidth;
    posClick = event.layerX;
    pourcentage = posClick / largeur < 1 ? posClick / largeur : 1;
    const newPos = Math.floor(((pourcentage * this.vgService.duration) / 1000) * 100) / 100;
    this.vgService.seekTime(newPos);
  }

  /**
   * ### Permet de changer la position de la tête de lecture avec un glissé
   * @param event L'évènement de drag
   */
  dragJog(event) {
    let largeur = 0,
      posClick = 0,
      pourcentage = 0;
    if (this.isDragging) {
      largeur = event.target.parentElement.clientWidth;
      posClick = event.layerX;
      pourcentage = posClick / largeur;
      setTimeout(() => {
        const newPos = Math.floor(((pourcentage * this.vgService.duration) / 1000) * 100) / 100;
        this.vgService.seekTime(newPos);
      }, 100);
    }
  }
}
