/**
 * ### Représente un objet livre
 */
export class Livre {
  /** Le titre */
  title: string;
  /** L'auteur */
  auteurs: any[];
  /** Qui lit? */
  interprete: any[];
  /** L'éditeur */
  editor: any;
  /** La durée du livre en secondes */
  duration: string;
  /** La description */
  description: string;
  /** L'url */
  url: string;
  /** L'identifiant du livre */
  id: number;
  /** L'année de parution du livre */
  annee: number;
  /** La version du texte */
  version: string;
  /** Les thèmes abordés */
  themes: { name: string }[];
  /** Le public cible */
  c_public: string;
  /** L'url de l'image de la couverture du livre */
  url_image: string;
  /** Si le livre est empruntable */
  empruntable: boolean;
  /** Si l'utilisateur l'a déjà emprunté */
  dejaEmprunte: boolean;
  /** Date d'emprunt */
  dateEmprunt?: Date;
  /** La durée de l'emprunt */
  dureeEmprunt?: number;
  /** date de restitution du livre */
  dateRestitution?: Date;

  /**
   * ### Le constructeur
   * @param infoLivre Les infos permettant de créer un objet Livre
   */
  constructor(infoLivre: any) {
    this.title = infoLivre.title;
    this.auteurs = infoLivre.auteurs;
    this.interprete = infoLivre.interprete;
    this.editor = infoLivre.editor;
    this.duration = infoLivre.duration;
    this.description = infoLivre.description;
    this.url = infoLivre.url;
    this.id = infoLivre.id;
    this.annee = infoLivre.annee;
    this.version = infoLivre.version;
    this.themes = infoLivre.themes;
    this.c_public = infoLivre.c_public;
    this.url_image = `${infoLivre.url_image}`;
    this.empruntable = infoLivre.empruntable;
    this.dejaEmprunte = infoLivre.dejaEmprunte;
    this.dateEmprunt = infoLivre.dateEmprunt ? infoLivre.dateEmprunt : null;
    this.dateRestitution = infoLivre.dateRestitution
      ? infoLivre.dateRestitution
      : infoLivre.date_restitution
      ? new Date(infoLivre.date_restitution.date)
      : null;
    this.dureeEmprunt = infoLivre.dureeEmprunt ? infoLivre.dureeEmprunt : infoLivre.duree ? infoLivre.duree : null;
  }

  /**
   * ### Donne la durée en nombre d'heures
   * @returns le nomre d'heures
   */
  // getHours(): number {
  //   return Math.floor(this.duration / 3600000);
  // }

  /**
   * ### Donne la durée en nombre de minutes
   * @returns le nomre de minutes
   */
  // getMinutes(): number {
  //   return Math.floor((this.duration - this.getHours() * 3600000) / 60000);
  // }

  /**
   * ### Donne la durée en nombre de secondes
   * @returns le nomre de secondes
   */
  // getSecondes(): number {
  //   return (this.duration - this.getHours() * 3600000 - this.getMinutes() * 60000) / 1000;
  // }
}
