import { LivresService } from 'app/_services/livres.service';
import { AppStateService } from '../../../_services/appstate.service';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AppState } from 'app/_services/appStateModels/app.state.model';
import { Observable } from 'rxjs';
import { Livre } from 'app/_classes/livre';

@Component({
  selector: 'bs-historique',
  templateUrl: './historique.component.html',
  styleUrls: ['./historique.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HistoriqueComponent implements OnInit {
  appState$: Observable<AppState>;
  listeLivres$: Observable<Livre[]>;
  constructor(private appStateService: AppStateService, private livresService: LivresService) {}

  ngOnInit() {
    this.appState$ = this.appStateService.appState$;
    this.listeLivres$ = this.livresService.getUserLendHistory();
  }
}
