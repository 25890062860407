import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Observable } from 'rxjs';
import { User } from '../_interfaces/user';
import { map } from 'rxjs/operators';

/** # Le service de gestion des utilisateurs */
@Injectable()
export class UserService {
  /** ### Le constructeur
   * @param resquestService Permet d'envoyer des requêtes à l'API
   */
  constructor(private requestService: RequestService) {}

  /**
   * ### Récupère les préférences de l'utilisateur.
   * @param idu L'identifiant de l'utilisateur
   */
  getUserPreference(idu: number): Observable<any> {
    return this.requestService.makeRequest('GET', `user/${idu}/preferences`);
  }

  /**
   * ### Récupère le profil de l'utilisateur.
   */
  getUserInfo(): Observable<User> {
    return this.requestService.makeRequest('GET', 'user/profil').pipe(
      map((data: any) => {
        return <User>{
          bibliotheque: { id: data.bib_id, nom: data.bname },
          email: data.email,
          fonction: data.fonction,
          fournisseur: null,
          id: data.id,
          nom: data.nom,
          prenom: data.prenom,
          profil: { saut: data.plLongueurSaut, fontSize: data.tailleTypo },
          role: data.name,
          telephone: data.telephone
        };
      })
    );
  }
}
