import { BehaviorSubject, Observable } from 'rxjs';
import { AppStateService } from 'app/_services/appstate.service';
import { Injectable } from '@angular/core';

export interface Theme {
  'color-accent': string;
  'color-text': string;
  'color-text-light': string;
  'color-secondary': string;
  'color-background': string;
}

export interface Themes {
  [name: string]: Theme;
}

@Injectable()
export class ThemesService {
  readonly themeProps = ['color-accent', 'color-text', 'color-text-light'];

  themes: Themes = {
    light: {
      'color-accent': '#A560EB',
      'color-text': '#FFB020',
      'color-text-light': '#FFC030',
      'color-secondary': '#A560EB',
      'color-background': '#f7f7f7'
    }
  };

  userTheme: Theme;

  _theme: BehaviorSubject<Theme> = new BehaviorSubject<Theme>(this.themes.light);

  constructor(private appStateService: AppStateService) {
    this.userTheme = {
      'color-accent': '',
      'color-text': '',
      'color-text-light': '',
      'color-secondary': '',
      'color-background': ''
    };
    this.appStateService.appState$.subscribe(data => {
      if (data.bibPrefs) {
        this.userTheme = data.bibPrefs.theme;
      }
      this._theme.next(this.userTheme);
    });
  }

  get currentTheme(): Observable<Theme> {
    return this._theme.asObservable();
  }
}
