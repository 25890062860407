import {Position} from '../_interfaces/position.interface';
import {AppStateService} from 'app/_services/appstate.service';
import {VideoGularService} from '../_modules/videogular-player/_services/videogular.service';
import {Injectable} from '@angular/core';

import {RequestService} from './request.service';
import {Livre} from '../_classes/livre';
import {Observable, BehaviorSubject, of} from 'rxjs';
import {map, mergeMap, tap, switchMap, takeWhile} from 'rxjs/operators';
import {BSNomsPropresPipe} from 'app/_modules/pipes/_pipes/noms.pipe';
import {BSStringToTimePipe} from 'app/_modules/pipes/_pipes/string.to.time.pipe';

/**
 * # Service de gestion des livres
 */
@Injectable()
export class LivresService {
  _currentUserBooks: BehaviorSubject<Livre[]> = new BehaviorSubject<Livre[]>([]);

  /**
   * ### Le constructeur
   * @param requestService Le service de gestion des requêtes HTTP vers l'API
   * @param namePipe
   * @param stringToTime
   * @param vgService
   * @param appStateService
   */
  constructor(
    private requestService: RequestService,
    private namePipe: BSNomsPropresPipe,
    private stringToTime: BSStringToTimePipe,
    private vgService: VideoGularService,
    private appStateService: AppStateService
  ) {
  }

  /**
   * ### Retourne les livres de la bibliothèque
   * @param filters Les filtres pour les recherches
   * @param limit Le nombre de livres affichés par page
   * @param offset La page à afficher
   */
  getBibCatalog(filters?: { key: string; value: string }[], limit?: number, offset?: number): Observable<any[]> {
    let f = '';
    if (filters) {
      filters.forEach(filter => {
        f += `?filters[${filter.key}]=${filter.value}&`;
      });
    }
    // return this.requestService.makeRequest('POST', `bibliotheque/catalogtest${f}`, { offset: offset, limit: limit });
    return this.requestService.makeRequest('GET', `bibliotheque/catalog`).pipe(
      map((livres: Livre[]) => {
        let list = [];
        livres.forEach((livre: Livre) => {
          livre = new Livre({
            ...livre,
            auteurs: this.namePipe.transform(livre.auteurs),
            interprete: this.namePipe.transform(livre.interprete)
          });
          list = [...list, livre];
        });
        return list;
      })
    );
  }

  getCountBibCatalog(): Observable<any[]> {
    return this.requestService.makeRequest('GET', `bibliotheque/catalog`).pipe(
      map((livres: Livre[]) => {
        return livres.length;
      })
    );
  }

  getCountCurrentUserBooks(): Observable<any[]> {
    return this.requestService.makeRequest('GET', `user/current`).pipe(
      map((liste: Livre[]) => {
        return liste.length;
      })
    );
  }

  /**
   * ### Retourne les infos d'un livre
   * @param idb  L'indentifiant du livre
   */
  getBookInfo(idb: number): Observable<any> {
    return this.requestService.makeRequest('GET', `book/${idb}`).pipe(
      map(livre => {
        const info = livre[0];
        return new Livre({
          id: idb,
          annee: info.produit.annee,
          auteurs: this.namePipe.transform(info.produit.auteur),
          c_public: info.produit.c_public,
          // duration: this.stringToTime.transform(info.produit.duration),
          duration: info.produit.duration,
          description: info.produit.description,
          editor: info.produit.editor.trim(),
          empruntable: info.empruntable,
          dejaEmprunte: info.dejaEmprunte,
          interprete: this.namePipe.transform(info.produit.interprete),
          themes: info.produit.themes,
          title: info.produit.title,
          url_image: info.produit.url_image,
          version: info.produit.version,
          dateEmprunt: info.date_emprunt ? new Date(info.date_emprunt.date) : null,
          dureeEmprunt: info.duree,
          dateRestitution: info.date_restitution ? new Date(info.date_restitution.date) : null
        });
      })
    );
  }

  /**
   * ### Obtient la couverture d'un livre
   * @param idb l'identifiant du livre
   */
  getBookCover(idb: number): Observable<string> {
    return this.requestService.makeRequest('GET', `book/${idb}/cover`);
  }

  /** ### Retourne les livres enmpruntés par l'utilisateur */
  getCurrentUserBooks(): Observable<any[]> {
    return this.requestService.makeRequest('GET', `user/current`).pipe(
      switchMap((liste: Livre[]) => {
        let result: Livre[] = [];
        liste.forEach((livre: any) => {
          livre = new Livre(livre.produit);
          livre.auteurs = this.namePipe.transform(livre.auteurs);
          livre.interprete = this.namePipe.transform(livre.interprete);
          result = [...result, livre];
        });
        result = result.sort((a, b) => {
          return a.title > b.title ? 1 : a.title < b.title ? -1 : 0;
        });
        this._currentUserBooks.next(result);
        return of(result);
      })
    );
  }

  get currentUserBooks$(): Observable<Livre[]> {
    return this._currentUserBooks.asObservable();
  }

  /**
   * ### Requête API d'emprunt d'un livre
   * @param idL L'identifiant du livre
   */
  emprunter(idL: number): Observable<any> {
    return this.requestService.makeRequest('POST', `book/${idL}/loan`);
  }

  /**
   * ### Requête API de restitution d'un livre
   * @param idL L'identifiant du livre
   */
  rendre(idL: number): Observable<any> {
    return this.requestService.makeRequest('POST', `book/${idL}/loanend`).pipe(
      tap((data: any) => {
        if (data.success === 'success') {
          this.vgService.pause();
          this.getCurrentUserBooks().subscribe();
          this.appStateService.resetPlayBook();
        }
      })
    );
  }

  getExtrait(idb: number): Observable<any> {
    return this.requestService.makeRequest('GET', `book/${idb}/url_extrait`);
  }

  getAllPositions(userId: number, bookId: number): Observable<Position[]> {
    return this.requestService.makeRequest('GET', `user/${userId}/${bookId}/positions`).pipe(
      map((allPos: Position[]) =>
        allPos.sort((a, b) => {
          return +a.chapitre.split('.m3u8')[0] < +b.chapitre.split('.m3u8')[0]
            ? -1
            : +a.chapitre.split('.m3u8')[0] > +b.chapitre.split('.m3u8')[0]
              ? 1
              : 0;
        })
      )
    );
  }

  setPosition(userId: number, produitId: number, position: Position): Observable<any> {
    const payload = {position: position.position};
    return this.requestService.makeRequest(
      'PATCH',
      `user/${userId}/${produitId}/${position.chapitre}/position`,
      payload
    );
  }

  /**
   * ### Marque un chapitre comme lu
   * @param userId
   * @param bookId
   * @param chapitre le titre du chapitre
   */
  markChapterAsRead(userId: number, bookId: number, chapitre: string): Observable<any> {
    return this.requestService.makeRequest('PATCH', `user/${userId}/${bookId}/${chapitre + '.m3u8'}/position`, {
      position_max: 1
    });
  }

  /**
   * ### Historique des emprunts de l'utilisateur
   *
   */

  getUserLendHistory(): Observable<Livre[]> {
    return this.requestService.makeRequest('GET', 'user/historique').pipe(
      switchMap((liste: any[]) => {
        let history: Livre[] = [];

        liste.forEach(element => {
          history = [
            ...history,
            new Livre({
              id: element.produit.id,
              annee: element.produit.annee,
              auteurs: this.namePipe.transform(element.produit.auteurs),
              c_public: element.produit.c_public,
              duration: this.stringToTime.transform(element.produit.duration),
              description: element.produit.description,
              editor: element.produit.editor.trim(),
              empruntable: element.empruntable,
              dejaEmprunte: element.dejaEmprunte,
              interprete: this.namePipe.transform(element.produit.interprete),
              themes: element.produit.themes,
              title: element.produit.title,
              url_image: element.produit.url_image,
              version: element.produit.version,
              dateEmprunt: element.date_emprunt
                ? new Date(element.date_emprunt.date)
                : element.date_created
                  ? new Date(element.date_created.date)
                  : null,
              dureeEmprunt: element.duree,
              dateRestitution: element.date_restitution ? new Date(element.date_restitution.date) : null
            })
          ];
        });
        history.sort((a, b) => (a.dateEmprunt > b.dateEmprunt ? -1 : a.dateEmprunt < b.dateEmprunt ? 1 : 0));
        return of(history);
      })
    );
  }
}
