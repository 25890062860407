import { ThemesModule } from './../themes/themes.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NavSwitchComponent } from './nav-switch/nav-switch.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule, RouterModule, FormsModule, ThemesModule],
  declarations: [NavSwitchComponent],
  exports: [NavSwitchComponent]
})
export class NavSwitchModule {}
