import { BsModalService } from './bs-modal.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsModalComponent } from './bs-modal/bs-modal.component';

@NgModule({
  imports: [CommonModule],
  declarations: [BsModalComponent],
  exports: [BsModalComponent],
  providers: [BsModalService]
})
export class BsModalModule {}
