import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';

import { style, state, animate, transition, trigger } from '@angular/animations';
import { takeWhile } from 'rxjs/operators';

import { AppStateService } from '../../../../_services/appstate.service';
import { RequestService } from '../../../../_services/request.service';

import { Livre } from '../../../../_classes/livre';
import { AppState } from 'app/_services/appStateModels/app.state.model';
import { NotificationsService } from 'angular2-notifications';
import { LivresService } from '../../../../_services/livres.service';

/**
 * # La boite de dialogue de confirmation d'emprun d'un livre.
 */
@Component({
  selector: 'bs-popin-emprunt',
  templateUrl: './popin-emprunt.component.html',
  styleUrls: ['./popin-emprunt.component.scss'],
  animations: [
    trigger('enterLeave', [
      transition(':enter', [
        style({ opacity: 0, display: 'none' }),
        animate(350, style({ opacity: 1, display: 'block' }))
      ]),
      transition(':leave', [animate(150, style({ opacity: 0 }))])
    ])
  ]
})
export class PopinEmpruntComponent implements OnInit, OnDestroy {
  /** Determine si on affiche ou non la boite de dialogue */
  @Input() visible: boolean;
  /** Emet l'état de visibilité pour le composant parent */
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  /** Le livre à emprunter */
  @Input() livre: Livre;

  /** Determine l'état des subscribes */
  isAlive: boolean;

  /** L'état de l'application */
  appState: AppState;

  /**
   * ### Le constructeur
   * @param appstateService Le service de gestion de l'état de l'application
   * @param requestService Le service de gestion des requêtes vers l'API
   * @param notificationsService Le service de gestion des notifications
   */
  constructor(
    private appstateService: AppStateService,
    private requestService: RequestService,
    private notificationsService: NotificationsService,
    private livresService: LivresService
  ) {}

  /**
   * ### Le début du cycle de vie du composant
   */
  ngOnInit() {
    this.isAlive = true;
    this.appstateService.appState$.pipe(takeWhile(() => this.isAlive)).subscribe(data => (this.appState = data));
  }

  /**
   * ### Le début du cycle de vie du composant
   */
  ngOnDestroy() {
    this.isAlive = false;
  }

  /**
   * ### Cache la boite de dialogue
   */
  hideDialog(): void {
    this.visibleChange.emit(false);
  }

  /**
   * ### Envoie un requête pour l'emprunt d'un livre
   */
  emprunter(): void {
    this.livresService
      .emprunter(this.livre.id)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        data => {
          this.notify(data);
        },
        error => console.error(error)
      );
  }

  /**
   * ### Envoie une notification en fonction du retour de la requête d'emprunt.
   * @param data Les données retournées par l'API suite à l'emprunt
   */
  notify(data): void {
    console.log(data);
    if (data.success) {
      this.notificationsService.success(
        'Livre emprunté!',
        `Le livre &laquo;${this.livre.title}&raquo; a été ajouté à la liste de vos emprunts!`,
        {
          timeOut: 3000,
          showProgressBar: true,
          pauseOnHover: true,
          clickToClose: false,
          clickIconToClose: true
        }
      );
      this.appstateService.toggleReloadList();
      this.hideDialog();
    } else {
      this.notificationsService.error('Livre déjà emprunté!', '', {
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: false,
        clickIconToClose: true
      });
    }
  }
}
