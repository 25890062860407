import { LivresService } from './../../../../_services/livres.service';
import { Observable, interval, of } from 'rxjs';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from '@angular/core';
import { Livre } from 'app/_classes/livre';
import { switchMap } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Component({
  selector: 'bs-player-extrait',
  templateUrl: './player-extrait.component.html',
  styleUrls: ['./player-extrait.component.scss']
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlayerExtraitComponent implements OnInit, OnChanges {
  @Input() livre: Livre;
  @Output() hide: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  player: any;
  position: string;
  audio: string;
  isPLaying = false;
  currentTime = 0;
  duration = 0;
  volume = 1;
  isDraggingVolume = false;
  showVolume = false;

  constructor(private livreService: LivresService) {
    this.player = new Audio();
  }

  ngOnInit() {
    this.hide.emit(false);
    this.player.volume = this.volume;
    this.player.ontimeupdate = () => {
      this.position = Math.round((this.player.currentTime / this.player.duration) * 100) + '%';
      this.currentTime = this.player.currentTime;
      this.duration = this.player.duration;
    };
    this.player.onplay = () => {
      this.isPLaying = true;
    };
    this.player.onpause = () => {
      this.isPLaying = false;
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.livre && this.livre) {
      this.livreService.getExtrait(this.livre.id).subscribe(data => {
        if (data.error && data.error === 'extrait not found') {
          this.hide.emit(true);
        } else {
          this.hide.emit(false);
          this.audio = data.url;
          this.player.src = this.audio;
        }
      });
    }
  }

  play() {
    this.player.play();
  }
  pause() {
    this.player.pause();
  }

  setVolume(event) {
    const pos = event.layerY;
    let newVolume = 0;
    let height = 0;
    if (event.srcElement.id !== 'volume__head') {
      height = event.srcElement.clientHeight;
      newVolume = (height - pos) / height;
    } else {
      height = event.srcElement.parentElement.clientHeight;
      newVolume = (height * this.volume - pos) / height;
    }
    this.volume = newVolume;
    this.player.volume = this.volume;
  }

  dragSetVolume(event) {
    if (this.isDraggingVolume) {
      const pos = event.layerY;
      let newVolume = 0;
      let height = 0;
      if (event.srcElement.id !== 'volume__head') {
        height = event.srcElement.clientHeight;
        newVolume = (height - pos) / height;
      } else {
        height = event.srcElement.parentElement.clientHeight;
        newVolume =
          (height * this.volume - pos) / height > 1
            ? 1
            : (height * this.volume - pos) / height < 0
            ? 0
            : (height * this.volume - pos) / height;
      }
      this.volume = newVolume;
      this.player.volume = this.volume;
    }
  }

  toggleMute() {
    this.player.muted = !this.player.muted;
  }

  mouseLeave($event) {
    this.showVolume = $event.movementY < 0 && $event.layerX < 27 && $event.layerX > 7;
  }
}
