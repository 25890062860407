import { Injectable } from '@angular/core';
import { Subject, Observable, ReplaySubject, of } from 'rxjs';
import { Breadcrumb } from '../_classes/breadcrumb';
import { switchMap, mapTo } from 'rxjs/operators';

/**
 * # Service de gestion du breadcrumb
 */
@Injectable()
export class BreadcrumbService {
  /**
   * Le subject du breadcrumb
   */
  breadcrumbSubject: ReplaySubject<Breadcrumb[]> = new ReplaySubject<Breadcrumb[]>(1);
  /**
   * Le breadcrumb actuel
   */
  currentBC: Breadcrumb[];

  /**
   * ### Le constructeur
   * Initialise le breadcrumb courant. (vide)
   */
  constructor() {
    this.currentBC = [];
  }

  /**
   * ### Définit le breadcrumb actuel
   * @param bc un Breadcrumb
   */
  setBreadCrumb(bc: Breadcrumb[]) {
    this.currentBC = bc;
    this.breadcrumbSubject.next(bc);
  }

  /**
   * ### Fournit le breadcrumb
   * @returns Le breadcrumb sous forme d'Observable Rxjs
   */
  getBreadCrumb(): Observable<Breadcrumb[]> {
    return this.breadcrumbSubject.asObservable();
  }

  /**
   * ### Fournit le breadcrumb actuel
   * @returns currentBC
   */
  getCurrentBreadCrumb(): Breadcrumb[] {
    return this.currentBC;
  }

  get previous(): Observable<Breadcrumb> {
    return this.breadcrumbSubject.asObservable().pipe(
      switchMap((bc: Breadcrumb[]) => {
        return of(
          bc[bc.length - 2]
            ? bc[bc.length - 2]
            : bc[bc.length - 1]
            ? bc[bc.length - 1]
            : new Breadcrumb('Accueil', '', false)
        );
      })
    );
  }
}
