import { Directive, HostBinding, OnInit, OnDestroy } from '@angular/core';
import { takeWhile } from 'rxjs/operators';

import { AppStateService } from 'app/_services/appstate.service';

@Directive({ selector: '[bsContrastBorderYellow]' })
export class ContrastBorderYellowDirective implements OnInit, OnDestroy {
  @HostBinding('style.border-color')
  color: string;
  isAlive = true;

  constructor(private appstateService: AppStateService) {}

  ngOnInit() {
    this.appstateService.appState$.pipe(takeWhile(() => this.isAlive)).subscribe(
      data => {
        if (data.user.userPrefs.contrast) {
          this.color = '#ff0';
        } else {
          this.color = null;
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  ngOnDestroy() {
    this.isAlive = false;
  }
}
