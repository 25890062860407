export * from './contrast-color-blue.directive';
export * from './contrast-color-yellow.directive';
export * from './contrast-border-yellow.directive';
export * from './contrast-border-force-yellow.directive';
export * from './contrast-bgc-blue.directive';
export * from './contrast-bgc-yellow.directive';
export * from './contrast-ol-focus-blue.directive';
export * from './contrast-ol-focus-yellow.directive';
export * from './theme.directive';
export * from './contrast-add-class.directive';

import { ContrastColorBlueDirective } from './contrast-color-blue.directive';
import { ContrastColorYellowDirective } from './contrast-color-yellow.directive';
import { ContrastBorderYellowDirective } from './contrast-border-yellow.directive';
import { ContrastBorderForceYellowDirective } from './contrast-border-force-yellow.directive';
import { ContrastBgcBlueDirective } from './contrast-bgc-blue.directive';
import { ContrastBgcYellowDirective } from './contrast-bgc-yellow.directive';
import { ContrastOlFocusBlueDirective } from './contrast-ol-focus-blue.directive';
import { ContrastOlFocusYellowDirective } from './contrast-ol-focus-yellow.directive';
import { ThemeDirective } from './theme.directive';
import { ContrastAddClassDirective } from './contrast-add-class.directive';

export const directives = [
  ContrastColorBlueDirective,
  ContrastColorYellowDirective,
  ContrastBorderYellowDirective,
  ContrastBorderForceYellowDirective,
  ContrastBgcBlueDirective,
  ContrastBgcYellowDirective,
  ContrastOlFocusBlueDirective,
  ContrastOlFocusYellowDirective,
  ContrastAddClassDirective,
  ThemeDirective
];
