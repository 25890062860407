import { Pipe, PipeTransform } from '@angular/core';

/**
 * # Retourne le nombre d'heures, minutes, secondes d'un temps en millisecondes (deux chiffres)
 *
 */
@Pipe({
  name: 'time'
})
/**
 * @param value Le temps en secondes
 * @param unit L'unité à retourner ('H', 'M' ou 'S')
 */
export class TimePipe implements PipeTransform {
  transform(value: any, unit: string): string {
    if (value && !isNaN(value)) {
      if (unit === 'H') {
        return '' + this.getHours(value);
      } else if (unit === 'M') {
        return ('0' + this.getMinutes(value)).slice(-2);
      } else if (unit === 'S') {
        return ('0' + this.getSeconds(value)).slice(-2);
      }
    }
    return null;
  }

  getHours(t: number): number {
    return Math.floor(t / 3600000);
  }

  getMinutes(t: number): number {
    return Math.floor((t - this.getHours(t) * 3600000) / 60000);
  }

  getSeconds(t: number): number {
    return Math.floor((t - this.getHours(t) * 3600000 - this.getMinutes(t) * 60000) / 1000);
  }
}
