import { Stream } from 'app/_modules/videogular-player/_models/stream.model.interface';
import { ReplaySubject, Observable, forkJoin, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { RequestService } from 'app/_services/request.service';
import { ChapterNamePipe } from '../../pipes/_pipes/chapter-name.pipe';
import { environment } from '../../../../environments/environment';
import { takeWhile } from 'rxjs/operators';

/**
 * # Le service de lecture des livres
 */
@Injectable()
export class PlayerService {
  playlistLivre: Stream[];
  isAlive = true;
  _playlistLivre: BehaviorSubject<Stream[]> = new BehaviorSubject<Stream[]>([]);

  /**
   * ### Le constructeur
   * @param requestService Le service de gestion des requêtes HTTP vers l'API
   */
  constructor(private requestService: RequestService, private chapterNamePipe: ChapterNamePipe) {}

  /**
   * ### Obtient la liste des chapitres d'un livre
   * @param idb L'identifiant du livre
   */
  getBookChapters(idb: number): Observable<any[]> {
    return this.requestService.makeRequest('GET', `book/${idb}/chapters`);
  }

  /**
   * ### Obtient le token de lecture d'un chapitre d'un livre
   * @param idb L'identifiant du livre
   * @param file Le nom du fichier
   */
  getToken(idb: number, file: string): Observable<string> {
    return this.requestService.makeRequest('POST', `book/${idb}/chapters`, file);
  }

  /**
   * ### Fabrique la liste de lecture
   */
  makePlayListLivre(idb: number, chapters: any[]): void {
    this.playlistLivre = [];
    const obs: Observable<any>[] = [];
    chapters.forEach(chapter => {
      obs.push(this.getToken(idb, `{"filename":"${chapter}"}`));
    });
    forkJoin(obs)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        data => {
          data.forEach((elt, i) => {
            const track = {
              id: this.chapterNamePipe.transform(chapters[i]),
              title: this.chapterNamePipe.transform(chapters[i]),
              url: `${environment.AUDIO_URL}${elt['token']}`
            };
            this.playlistLivre = [...this.playlistLivre, track];
          });
        },
        error => console.error(error),
        () => {
          this.playlistLivre = this.playlistLivre.sort((a, b) => {
            return +a.id < +b.id ? -1 : +a.id > +b.id ? 1 : 0;
          });
          this.setPlaylist(this.playlistLivre);
        }
      );
  }

  setPlaylist(liste: Stream[]) {
    this._playlistLivre.next(liste);
  }

  get playlistLivre$(): Observable<Stream[]> {
    return this._playlistLivre.asObservable();
  }
}
