import { VideoGularService } from './../../../videogular-player/_services/videogular.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bs-player-widget-volume',
  templateUrl: './player-widget-volume.component.html',
  styleUrls: ['./player-widget-volume.component.scss']
})
export class PlayerWidgetVolumeComponent implements OnInit {
  isDragging = false;
  volumeBackup: number;

  constructor(public vgService: VideoGularService) {}

  ngOnInit() {}

  /**
   * ### Permet de changer la position de la tête de lecture avec un clique
   * @param event L'évènement de click
   */
  jog(event) {
    let largeur = 0,
      posClick = 0,
      pourcentage = 0;

    largeur = event.target.parentElement.clientWidth;
    posClick = event.layerX;
    pourcentage = posClick / largeur;
    this.vgService.volume = pourcentage;
  }

  /**
   * ### Permet de changer la position de la tête de lecture avec un glissé
   * @param event L'évènement de drag
   */
  dragJog(event) {
    let largeur = 0,
      posClick = 0,
      pourcentage = 0;
    if (this.isDragging) {
      largeur = event.target.parentElement.clientWidth;
      posClick = event.layerX;
      pourcentage = posClick / largeur;
      setTimeout(() => {
        this.vgService.volume = pourcentage;
      }, 250);
    }
  }

  toggleMute() {
    if (this.vgService.volume > 0) {
      this.volumeBackup = this.vgService.volume;
      this.vgService.volume = 0;
    } else {
      this.vgService.volume = this.volumeBackup;
    }
  }
}
