import { ChapterNamePipe } from './chapter-name.pipe';
import { TimePipe } from './time.pipe';
import { BSNomsPropresPipe } from './noms.pipe';
import { BSStringToTimePipe } from './string.to.time.pipe';
import { BSJumpTimePipe } from './jump-time.pipe';

export * from './chapter-name.pipe';
export * from './time.pipe';
export * from './noms.pipe';
export * from './string.to.time.pipe';
export * from './jump-time.pipe';

export const Pipes = [ChapterNamePipe, TimePipe, BSNomsPropresPipe, BSStringToTimePipe, BSJumpTimePipe];
