import {AppStateService} from 'app/_services/appstate.service';
import {NavSwitchItem} from '../../_modules/nav-switch/nav-switch.model';
import {Component, OnInit, OnDestroy} from '@angular/core';
import {takeWhile} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AppState} from '../../_services/appStateModels';
import {UserService} from '../../_services/user.service';
import {User} from '../../_interfaces/user';
import {LivresService} from '../../_services/livres.service';

/**
 * # Représente l'entête de la page
 * Permet d'afficher les boutons de réglage :
 * * du mode dyslexie
 * * du mode contrast
 * * des préférences du lecteur audio (nombre de pistes affichées et durée des sauts de la lecture)
 */
@Component({
  selector: 'bs-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  /** Determine l'état des subscribes */
  isAlive: boolean;

  /** Le nom de la classe du body */
  bodyClassName = 'cookiebanner theme-dark';
  /** L'état global de l'application */
  appState: AppState;
  /** Si on survole le bouton de la popin des réglages du player */
  settingsHover = false;
  /** Permet de définir la valeur de rotation pour l'animation du bouton des réglages du player */
  rotate = '';
  /** Les préférences de l'utilisateur */
  // userPrefs;
  /** L'utilisateur courant */
  currentUser: User;

  menu: NavSwitchItem[] = [
    {label: 'En ce moment', path: '/accueil', count: null},
    {label: 'Mes livres audio', path: '/mes-livres', count: null}
  ];

  /**
   * ### Le constructeur
   * @param appStateService Le service de gestion de l'état global de l'appli
   * @param userService
   * @param router
   * @param livresService
   */
  constructor
  (
    private appStateService: AppStateService,
    private userService: UserService,
    private router: Router,
    private livresService: LivresService
  ) {
  }

  /**
   * ### Le début du cycle de vie du composant
   */
  ngOnInit() {
    this.isAlive = true;
    this.appStateService.appState$.pipe(takeWhile(() => this.isAlive)).subscribe(data => this.updateAppState(data));
    this.appStateService.getBibPrefs();

    let count: any;
    this.livresService
      .getCountBibCatalog()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        data => {
          count = data;
        },
        error => console.error(error),
        () => {
          this.menu[0].count = count;
        }
      );
    let countBorrowed: any;
    this.livresService
      .getCountCurrentUserBooks()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        data => {
          countBorrowed = data;
        },
        error => console.error(error),
        () => {
          this.menu[1].count = countBorrowed;
        }
      );

  }

  /**
   * ### La fin du cycle de vie du composant
   * Met fin au subscribes
   */
  ngOnDestroy() {
    this.appStateService.dispose();
    this.isAlive = false;
  }

  /**
   * ### Met à jour l'interface
   * En fonction d'un nouvel état de l'application
   * @param state Le nouvel état de l'appli
   */
  updateAppState(state: AppState) {
    this.appState = state;
    this.setBodyClass();
    this.setFontSize();
  }

  /**
   * ### Active/désactive le mode contrast
   */
  toggleContrast(): void {
    this.appStateService.setIsContrast(!this.appState.user.userPrefs.contrast);
  }

  /**
   * ### Active/désactive le mode dyslexie
   */
  toggleDyslexia(): void {
    this.appStateService.setIsDyslexia(!this.appState.user.userPrefs.dyslexia);
  }

  /**
   * ### Gère la class de la balise body
   * En fonction des réglages de l'état de l'appli
   */
  setBodyClass() {
    if (this.appState.user.userPrefs.dyslexia && this.appState.user.userPrefs.contrast) {
      this.bodyClassName = `cookiebanner dyslexia theme-contrast`;
    } else if (this.appState.user.userPrefs.dyslexia && !this.appState.user.userPrefs.contrast) {
      this.bodyClassName = `cookiebanner dyslexia theme-${this.appState.bibPrefs.themeName}`;
    } else if (!this.appState.user.userPrefs.dyslexia && this.appState.user.userPrefs.contrast) {
      this.bodyClassName = `cookiebanner theme-contrast`;
    } else {
      this.bodyClassName = `cookiebanner  theme-${this.appState.bibPrefs.themeName}`;
    }

    document.body.className = this.bodyClassName;
  }

  /**
   * ### Règle la taille des polices de caractère
   */
  setFontSize(): void {
    document.documentElement.setAttribute('style', `font-size:${this.appState.user.userPrefs.fontSize}%`);
  }

  /**
   * ### Augmente la taille de la police de caractère
   */
  increaseFontSize(): void {
    this.appStateService.setFontSize(Math.round(this.appState.user.userPrefs.fontSize * 1.125));
  }

  /**
   * ### Diminue la taille de la police de caractère
   */
  decreaseFontSize(): void {
    this.appStateService.setFontSize(Math.round(this.appState.user.userPrefs.fontSize / 1.125));
  }

  /**
   * ### Ré-initialise la taille de la police de caractère
   */
  resetFontSize(): void {
    this.appStateService.setFontSize(75);
  }

  /**
   * ### Quand on appuie sur le bouton de déconnexion
   * * Redirige l'utilisateur vers la page de connexion
   */
  toLogOut(): void {
    this.router.navigate(['/login']);
  }

  toggleMenu() {
    this.appStateService.toggleMobileMenu();
  }
}
