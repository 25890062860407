import { Stream } from './../_models/stream.model.interface';
import { Injectable } from '@angular/core';
import { VgAPI, VgEvents, VgStates } from 'videogular2/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IMediaSubscriptions } from 'videogular2/src/core/vg-media/i-playable';
import { tap } from 'rxjs/operators';

@Injectable()
export class VideoGularService {
  playerState: BehaviorSubject<string> = new BehaviorSubject('');
  subscriptions: IMediaSubscriptions;
  _playlist: BehaviorSubject<Stream[]> = new BehaviorSubject<Stream[]>([]);
  _currentStream: BehaviorSubject<Stream> = new BehaviorSubject<Stream>({ id: 1, title: '', url: '' });
  _autoplay: BehaviorSubject<boolean> = new BehaviorSubject(false);

  _vgAPI: BehaviorSubject<VgAPI> = new BehaviorSubject<VgAPI>(null);

  saut = 10;

  constructor(private player: VgAPI) {}

  initVgApi(player: VgAPI) {
    this.player = player;
    this._vgAPI.next(this.player);
    this.initSubscriptions();
    this.playerState.next(this.player.state);
    this._autoplay.pipe(tap(x => console.log('autoplay:', x)));
  }

  initSubscriptions() {
    this.subscriptions = this.player.subscriptions;
    this.subscriptions.loadStart.subscribe(data => {
      this.setState(VgStates.VG_LOADING);
    });
    this.subscriptions.play.subscribe(data => console.log('*** PLAY'));
    this.subscriptions.playing.subscribe(data => {
      this.setState(VgStates.VG_PLAYING);
    });
    this.subscriptions.pause.subscribe(data => {
      this.setState(VgStates.VG_PAUSED);
    });
    this.subscriptions.ended.subscribe(data => {
      this.setState(VgStates.VG_PAUSED);
      this.next();
    });
  }

  setPlaylist(playlist: Stream[]) {
    this._playlist.next(playlist);
  }

  setCurrentStream(stream: Stream) {
    this._currentStream.next(stream);
  }

  setAutoplay(value: boolean) {
    this._autoplay.next(value);
  }

  setState(state: string) {
    this.player.state = state;
    this.playerState.next(this.player.state);
  }

  play() {
    this.player.play();
  }

  playTrack(index) {
    this._currentStream.next(this._playlist.value.find(elt => elt.id === index));
    this.player.subscriptions.canPlay.subscribe(() => {
      this.player.play();
    });
  }

  pause() {
    if (this.player.isPlayerReady) {
      // console.log('|| PAUSE');
      this.player.pause();
    }
  }

  seekTime(time: number) {
    this._autoplay.next(true);
    this.player.seekTime(time);
  }

  ff() {
    const newPos = Math.round((this.position / 1000 + this.saut) * 100) / 100;
    this.player.seekTime(newPos);
  }

  rw() {
    this.player.seekTime(this.position / 1000 - this.saut);
  }

  next() {
    const index = this._playlist.value.findIndex(elt => elt.id === this._currentStream.value.id);
    if (index < this._playlist.value.length - 1) {
      this.playTrack(this._playlist.value[index + 1].id);
    }
  }

  previous() {
    const index = this._playlist.value.findIndex(elt => elt.id === this._currentStream.value.id);
    if (this.player.time.current > 10000) {
      this.seekTime(0);
    } else {
      if (index > 0) {
        this.playTrack(this._playlist.value[index - 1].id);
      }
    }
  }

  set rwffSaut(time: number) {
    this.saut = time;
  }

  get state(): Observable<string> {
    return this.playerState.asObservable();
  }

  get duration(): number {
    return this.player.duration * 1000;
  }

  get position(): number {
    return this.player.time.current;
  }

  get percentPosition(): string {
    return (this.player.currentTime / this.player.duration) * 100 + '%';
  }

  get factorPosition(): number {
    return Math.floor((this.player.time.current / this.player.time.total) * 10000) / 10000;
  }

  get left(): number {
    return this.player.time.left;
  }

  get subscriptionStream(): IMediaSubscriptions {
    return this.subscriptions;
  }

  get canPlay(): Observable<any> {
    return this.player.subscriptions.canPlay;
  }

  get _playlist$(): Observable<Stream[]> {
    return this._playlist.asObservable();
  }

  get _currentStream$(): Observable<Stream> {
    return this._currentStream.asObservable();
  }

  get currentStream(): Stream {
    return this._currentStream.value;
  }

  get _autoplay$(): Observable<boolean> {
    return this._autoplay.asObservable();
  }

  get playerVgApi(): Observable<VgAPI> {
    return this._vgAPI.asObservable();
  }

  get volume(): number {
    return this.player.volume;
  }

  set volume(vol: number) {
    this.player.volume = vol;
  }
}
