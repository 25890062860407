import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { takeWhile, tap } from 'rxjs/operators';

import { LivresService } from '../../../../_services/livres.service';
import { AppStateService } from '../../../../_services/appstate.service';

import { Livre } from '../../../../_classes/livre';
import { AppState } from 'app/_services/appStateModels/app.state.model';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';

/**
 * # Une carte d'un livre dans une liste de livres
 */
@Component({
  selector: 'bs-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardComponent implements OnInit, OnDestroy {
  /** L'objet Livre */
  @Input() livre: Livre;
  /** Permet d'ouvrir la popin de confirmation de l'emprunt d'un livre */
  confirmEmprunt = false;
  /** Si on affiche ou non les détails du livre */
  displayDetails: boolean;
  /** Determine l'état des subscribes */
  isAlive: boolean;
  /** L'état de l'application */
  appState: AppState;
  /** La date calculée de la restition du livre */
  dateRestitution: Date;

  /**
   * ### Le constructeur
   */
  constructor(
    private livresService: LivresService,
    private appstateService: AppStateService,
    private router: Router,
    private notificationsService: NotificationsService,
    private cd: ChangeDetectorRef
  ) {}

  /**
   * ### Le début du cycle de vie du composant.
   */
  ngOnInit() {
    this.displayDetails = false;
    this.isAlive = true;
    this.dateRestitution = new Date(new Date(Date.now()).getTime() + this.livre.dureeEmprunt * 1000 * 60 * 60 * 24);
    this.appstateService.appState$
      .pipe(
        takeWhile(() => this.isAlive),
        tap(() => {
          this.cd.markForCheck();
        })
      )
      .subscribe(data => (this.appState = data));
  }

  /**
   * ### La fin du cycle de vie du composant
   * Met fin au subscribes
   */
  ngOnDestroy() {
    this.isAlive = false;
  }

  /**
   * ###
   */
  /* toggleDetails() {
    this.displayDetails = !this.displayDetails;
  } */

  /** ### Lance la lecture d'un livre déjà emprunté */
  playLivre(): void {
    this.appstateService.playBook(this.livre);
    this.router.navigateByUrl('/mes-livres');
  }

  /**
   * ### Permet de lancer l'affichage de la popin de confirmation de l'emprunt d'un livre
   */
  emprunter(): void {
    this.confirmEmprunt = !this.confirmEmprunt;
  }

  confirmEmprunter() {
    this.livresService
      .emprunter(this.livre.id)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        data => data,
        error => console.error(error),
        () => {
          this.emprunter();
          this.appstateService.toggleReloadList();
          this.notificationsService.success(
            'Livre emprunté!',
            `Le livre &laquo;${this.livre.title}&raquo; a été ajouté à la liste de vos emprunts!`,
            {
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: false,
              clickIconToClose: true
            }
          );
        }
      );
  }
}
