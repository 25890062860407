import { throwError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { NotificationsService } from 'angular2-notifications';
import { catchError } from 'rxjs/operators';

/**
 * # Service de gestion des requêtes
 * ## Pour Communiquer avec l'API
 *
 *
 * &nbsp;
 *
 * Permet de :
 * * formuler des requêtes vers l'API
 * * Ajouter à chaque requête le header permettant de l'authentifier auprès de l'API
 */
@Injectable()
export class RequestService {
  /** L'URL de l'API */
  API_URL: string = environment.API_URL;

  /**
   * Le constructeur
   * @param http Le client HTTP
   * @param router Le routeur Angular
   */
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private notificationsService: NotificationsService
  ) {}

  /**
   * Ajouter un header de type 'Authorization': 'X-Auth-Token fldkfgnldkfgnldkfn' à chaque requête
   *
   * @returns Le header
   */
  addAuthorizationHeader(): any {
    let authorizationHeader = null;
    const bibliostreamUser = JSON.parse(localStorage.getItem('bibliostreamUser'));

    if (bibliostreamUser && bibliostreamUser.value) {
      authorizationHeader = new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': bibliostreamUser.value
      });
    }

    return { headers: authorizationHeader };
  }

  /**
   * Fabrique une **requête** :
   * * Prend en compte la méthode de la requête http
   * * Ajoute l'AuthorizationHeader
   * * Gère les erreurs
   *
   * @param method La méthode http (GET, POST, PATCH, DELETE)
   * @param url L'URL de l'API où on envoie la requête
   * @param body Le contenu de la requête.
   * @returns La réponse du serveur sous forme d'Observable
   */
  makeRequest(method: string, url: string, body?: any): any {
    switch (method) {
      case 'GET': {
        return (
          this.httpClient
            .get(this.API_URL + url, this.addAuthorizationHeader())
            // .map(res => res.json())
            .pipe(catchError(err => this.handleError(err)))
        );
      }
      case 'POST': {
        return (
          this.httpClient
            .post(this.API_URL + url, body, this.addAuthorizationHeader())
            // .map(res => res.json())
            .pipe(catchError(err => this.handleError(err)))
        );
      }
      case 'PATCH': {
        return (
          this.httpClient
            .patch(this.API_URL + url, body, this.addAuthorizationHeader())
            // .map(res => res.json())
            .pipe(catchError(err => this.handleError(err)))
        );
      }
    }
  }

  /**
   * ### Gestion des erreurs
   * @param error L'erreur renvoyée par l'API
   */
  handleError(error: Response): Observable<any> {
    switch (error.status) {
      case 401: {
        // tslint:disable-next-line:quotemark
        console.error("Vous n'êtes pas autorisé à effectuer cette requête");
        // tslint:disable-next-line:quotemark
        this.notificationsService.error("Vous n'êtes pas authentifié!", 'Vous allez être déconnecté.', {
          timeOut: 3000,
          showProgressBar: true,
          pauseOnHover: true,
          clickToClose: false,
          clickIconToClose: true
        });
        this.router.navigate(['/login']);
        break;
      }
      default: {
        console.error('%cERREUR!!!', 'color:#FF0000', error.statusText);
      }
    }
    return throwError(new Error(error.status.toString()));
  }
}
