// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  API_URL: 'https://devapi.bibliostream.fr/api/',
  version: 'DEV',
  DOC_URL: 'http://localhost:8080',
  AUDIO_URL: 'https://devapi.bibliostream.fr/player/file/',
  DEFAULT_FONTSIZE: 62
};

/* http://devapi.bibliostream.fr/app_dev.php/player/file/f39409873730c1e206b037ad1c09fa36    avec le app_dev.php au milieu  */
