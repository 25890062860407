import { ThemesService, Theme } from './../../../themes/_services/themes.service';
import { VideoGularService } from 'app/_modules/videogular-player/_services/videogular.service';
import { Observable } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeWhile, pluck } from 'rxjs/operators';

import { AppStateService } from 'app/_services/appstate.service';
import { AppState } from 'app/_services/appStateModels/app.state.model';
import { Stream } from 'app/_modules/videogular-player/_models/stream.model.interface';
import { VgStates } from 'videogular2/core';
import { Livre } from 'app/_classes/livre';

@Component({
  selector: 'bs-player-widget',
  templateUrl: './player-widget.component.html',
  styleUrls: ['./player-widget.component.scss']
})
export class PlayerWidgetComponent implements OnDestroy, OnInit {
  isAlive: boolean;
  appState: AppState;
  currentPlaying: Observable<Stream>;
  isPlaying: boolean;
  isPlayerLoading = false;
  visible = true;
  theme: Observable<Theme>;
  livre$: Observable<Livre>;

  constructor(
    public vgService: VideoGularService,
    private appStateService: AppStateService,
    private themeService: ThemesService
  ) {}
  /**
   * ### Début du cycle de vie du composant.
   * * Active les subscribes
   */
  ngOnInit() {
    this.isAlive = true;
    this.handleAppState();
    this.getPlayerInfo();
    this.theme = this.themeService.currentTheme;
  }
  /**
   * ### Fin du cycle de vie du composant.
   * * Désactive les subscribes
   */
  ngOnDestroy() {
    this.isAlive = false;
  }

  handleAppState(): void {
    this.appStateService.appState$.pipe(takeWhile(() => this.isAlive)).subscribe(data => {
      this.appState = data;
      this.visible = this.appState.uiDisplay.displayPlayerWidget && this.appState.livreEnCours.playing ? true : false;
    });
    this.livre$ = this.appStateService.appState$.pipe(pluck('livreEnCours', 'playing'));
  }

  getPlayerInfo(): void {
    this.vgService.state.pipe(takeWhile(() => this.isAlive)).subscribe(data => {
      switch (data) {
        case VgStates.VG_PLAYING:
          this.isPlaying = true;
          this.isPlayerLoading = false;
          break;
        case VgStates.VG_PAUSED:
          this.isPlaying = false;
          break;
        case VgStates.VG_LOADING:
          this.isPlayerLoading = true;
          break;
      }
    });
    this.currentPlaying = this.vgService._currentStream$;
  }

  /** ### Change le mode lecture/pause du lecteur */
  togglePlaying() {
    if (!this.isPlaying) {
      this.vgService.play();
    } else {
      this.vgService.pause();
    }
  }
}
