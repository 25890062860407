import { Component, OnInit, OnDestroy, Input, ViewEncapsulation } from '@angular/core';
import { takeWhile } from 'rxjs/operators';

import { Livre } from 'app/_classes/livre';
import { AppState } from 'app/_services/appStateModels/app.state.model';
import { AppStateService } from '../../../../_services/appstate.service';
import { Router } from '@angular/router';
import { LivresService } from '../../../../_services/livres.service';
import { NotificationsService } from 'angular2-notifications';

/**
 * # Une petite carte pour l'affichage d'un livre sur les petits écran (544px)
 */
@Component({
  selector: 'bs-small-card',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './small-card.component.html',
  styleUrls: ['./small-card.component.scss']
})
export class SmallCardComponent implements OnInit, OnDestroy {
  /** Le livre à afficher */
  @Input()
  livre: Livre;
  /** Détermine l'état des subscribes */
  isAlive: boolean;
  /** L'état de l'application */
  appState: AppState;
  /** Permet d'ouvrir la popin de confirmation de l'emprunt d'un livre */
  confirmEmprunt = false;
  /** La date calculée de la restition du livre */
  dateRestitution: Date;

  /**
   * ### Le constructeur
   * @param appStateService  Le service de gestion de l'état de l'application
   */
  constructor(
    private appStateService: AppStateService,
    private router: Router,
    private livresService: LivresService,
    private notificationsService: NotificationsService
  ) {}

  /** ### Initialisation du composant */
  ngOnInit() {
    this.isAlive = true;
    this.dateRestitution = new Date(new Date(Date.now()).getTime() + this.livre.dureeEmprunt * 1000 * 60 * 60 * 24);

    this.handleAppState();
  }

  /**
   * ### La fin du cycle de vie du composant
   * Met fin aux subscribes
   */
  ngOnDestroy() {
    this.isAlive = false;
  }

  /**
   * ### Gestion de l'état de l'application.
   * * Si l'état n'est pas définit dans le composant, on va le cercher
   * * On s'abonne au Subject
   */
  handleAppState(): void {
    this.appStateService.appState$.pipe(takeWhile(() => this.isAlive)).subscribe(data => {
      this.appState = data;
    });
  }

  playLivre() {
    this.appStateService.playBook(this.livre);
    this.router.navigateByUrl('/mes-livres');
  }

  /**
   * ### Permet de lancer l'affichage de la popin de confirmation de l'emprunt d'un livre
   */
  emprunter(): void {
    this.confirmEmprunt = !this.confirmEmprunt;
  }

  confirmEmprunter() {
    this.livresService
      .emprunter(this.livre.id)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        data => data,
        error => console.error(error),
        () => {
          this.emprunter();
          this.appStateService.toggleReloadList();
          this.notificationsService.success(
            'Livre emprunté!',
            `Le livre &laquo;${this.livre.title}&raquo; a été ajouté à la liste de vos emprunts!`,
            {
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: false,
              clickIconToClose: true
            }
          );
        }
      );
  }
}
