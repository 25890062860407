import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './_guards/auth.guard';

import { LoginComponent } from './_components/login/login.component';
import { AccueilComponent } from './_components/accueil/accueil.component';
import { LayoutComponent } from './_components/layout/layout.component';
import { PasswordResetComponent } from "./_components/password-reset/password-reset.component";

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'accueil', pathMatch: 'full' },
      {
        path: 'accueil',
        component: AccueilComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'livre/:idl',
        loadChildren: 'app/_modules/livre-details/livre-details.module#LivreDetailsModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'mes-livres',
        loadChildren: 'app/_modules/mes-livres/mes-livres.module#MesLivresModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'parametres',
        loadChildren: 'app/_modules/parameters/parameters.module#ParametersModule',
        canActivate: [AuthGuard]
      }
    ]
  },
  { path: 'login', component: LoginComponent },
  { path: 'password-reset', component: PasswordResetComponent },
  /* Si la route n'existe pas on redirige vers '' */
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always', useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
