import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Pipes } from './_pipes';

@NgModule({
  imports: [CommonModule],
  declarations: [...Pipes],
  exports: [...Pipes],
  providers: [...Pipes]
})
export class PipesModule {}
