import { AppStateService } from 'app/_services/appstate.service';
import { BsModalService } from './../_modules/bs-modal/bs-modal.service';
import { environment } from './../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { NgxXml2jsonService } from 'ngx-xml2json';
import { switchMap, catchError } from 'rxjs/operators';

/**
 * # Empêche d'accéder aux routes sans authorisation
 */
@Injectable()
export class AuthGuard implements CanActivate {
  /** L'URL de retour après authorisation */
  returnUrl: string;

  /**
   * ### Le constructeur
   * @param router Le routeur
   */
  constructor(
    private router: Router,
    private http: HttpClient,
    private xmlParser: NgxXml2jsonService,
    private modalService: BsModalService,
    private appStateService: AppStateService
  ) {}

  /**
   * ### La fonction qui vérifie sur l'utilisateur est authentifié
   * @param next
   * @param state
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (state.root.queryParams.ARCHID) {
      this.assaAuthenticate(state);
    } else {
      if (state.url === '/') {
        this.returnUrl = '/';
      } else {
        this.returnUrl = state.url.slice(1);
      }
      if (localStorage.getItem('bibliostreamUser')) {
        return true;
      } else {
        this.router.navigate(['login'], {
          queryParams: { returnUrl: state.url.slice(1) }
        });
      }
    }
  }

  /* assaAuthenticate2(state: RouterStateSnapshot) {
    console.log('### AssaAuthenticate');
    const returnUrl = state.url;
    const params = state.root.queryParams;
    this.http
      .get(params.AssaCallback /* , { headers: header } )
      .pipe(
        catchError((err, caught) => {
          console.error('### Erreur de SSO ###');
          console.log(caught);
          return err;
        }),
        switchMap((data: any) => {
          console.log('assaAuthenticate SwitchMap');
          console.log('data: ', data);
          const parser = new DOMParser();
          const xml = parser.parseFromString(data, 'text/xml');
          const session: any = this.xmlParser.xmlToJson(xml);
          return this.http.post(`${environment.API_URL}user/check`, session.ASSA.Response.User);
        })
      )
      .subscribe((data: any) => {
        console.log('%c~~~assaAuthenticateCheck:', 'color:#0044FF', data);
        if (data.success === false) {
          this.modalService.setErrorMessage$(`Vous tentez de vous connecter à une bibliothèque invalide.`);
        } else if (data.value) {
          this.modalService.setModalIsVisible$(false);
          this.saveDataToLocalStorage(data);
          this.router.navigate([returnUrl]);
          this.appStateService.getBibPrefs();
        }
      });
  } */

  assaAuthenticate(state: RouterStateSnapshot) {
    console.log('### New AssaAuthenticate');
    const returnUrl = state.url;
    const params = state.root.queryParams;
    // const headers = new HttpHeaders();
    const formData = new FormData();
    formData.append('AssaCallback', params.AssaCallback);

    const headers = new HttpHeaders({ enctype: 'multipart/form-data' });
    // headers.set('Content-Type', 'application/json');
    console.log('params: ', params);
    this.http.post(`${environment.API_URL}user/check`, formData).subscribe((data: any) => {
      console.log('~~~~Check data: ', data);
      if (data.success === false) {
        this.modalService.setErrorMessage$(`Vous tentez de vous connecter à une bibliothèque invalide.`);
      } else if (data.value) {
        this.modalService.setModalIsVisible$(false);
        this.saveDataToLocalStorage(data);
        this.router.navigate([returnUrl]);
        this.appStateService.getBibPrefs();
      }
    });
  }

  /**
   * ### Sauvegarde les données reçues (token et utilisateur) dans le LocalStorage
   * @param data Les données à sauvegarder
   */
  saveDataToLocalStorage(data: any) {
    localStorage.setItem('bibliostreamUser', JSON.stringify(data));
  }
}
