import { Directive, OnChanges, Input, ElementRef } from '@angular/core';

/**
 * Utilisation:
 * <myComponent [bsTheme]="{'color-main': '#bada55'}"></myComponent>
 */
@Directive({
  selector: '[bsTheme]'
})
export class ThemeDirective implements OnChanges {
  @Input('bsTheme') bsTheme: { [prop: string]: string };

  constructor(private el: ElementRef<HTMLElement>) {}

  ngOnChanges() {
    Object.keys(this.bsTheme).forEach(prop => {
      this.el.nativeElement.style.setProperty(`--${prop}`, this.bsTheme[prop]);
    });
  }
}
