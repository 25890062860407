import { Component, OnInit, Input, ChangeDetectionStrategy, SimpleChanges, OnChanges } from '@angular/core';
import { Stream } from 'app/_modules/videogular-player/_models/stream.model.interface';

@Component({
  selector: 'bs-playlist-track-item',
  templateUrl: './playlist-track-item.component.html',
  styleUrls: ['./playlist-track-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlaylistTrackItemComponent implements OnInit, OnChanges {
  @Input() track: Stream;
  @Input() index: number;
  @Input() isPlayed: boolean;
  @Input() progression: string;
  @Input() totalRead: number;

  isNotTouched = true;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.totalRead && this.totalRead > 0) {
      this.isNotTouched = false;
    }
  }
}
