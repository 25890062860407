import { Component, OnInit, OnDestroy } from '@angular/core';
import { BreadcrumbService } from '../../_services/breadcrumb.service';
import { takeWhile } from 'rxjs/operators';

import { Breadcrumb } from '../../_classes/breadcrumb';
/**
 * # Le composant d'affichage du breadcrumb
 */
@Component({
  selector: 'bs-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  /** L'ensemble du breadcrumb */
  crumbs: Breadcrumb[] = [];
  /** Determine l'état des subscribes */
  isAlive: boolean;

  /**
   * ### Le constructeur
   * @param breadcrumbService Le service de propagation du breadcrumb
   */
  constructor(private breadcrumbService: BreadcrumbService) {}

  /**
   * Le début du cycle de vie du composant
   * * Obtient le breadcrumb complet en fonction de la vue
   * */
  ngOnInit() {
    this.isAlive = true;
    this.breadcrumbService
      .getBreadCrumb()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(data => (this.crumbs = data));
  }
  /**
   * La fin du cycle de vie du composant
   * * Met fin aux subscribes
   *
   */
  ngOnDestroy() {
    this.isAlive = false;
  }
}
