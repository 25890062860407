import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'BSStringToTime'
})
export class BSStringToTimePipe implements PipeTransform {
  transform(value: string): number {
    if (value) {
      const t = value.split(' ');
      const h = t[1];
      const m = t[3];
      const time = +h * 3600000 + +m * 60000;
      return time;
    }
    return 0;
  }
}
