export class ReglagesPlayer {
  dureeSauts: any[] = [
    { label: '15 secondes', value: 15 },
    { label: '30 secondes', value: 30 },
    { label: '45 secondes', value: 45 },
    { label: '1 minute', value: 60 },
    { label: '2 minutes', value: 120 },
    { label: '5 minutes', value: 300 },
    { label: '10 minutes', value: 600 }
  ];
}
