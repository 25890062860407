import { RequestService } from './request.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class BibliothequeService {
  constructor(private requete: RequestService) {}

  /**
   * ### Obtient les préférences de la bibliothèque
   *
   * @param idb L'identifiant de la bibliothèque
   */
  getBibPrefs(idb: number): Observable<any> {
    return this.requete.makeRequest('GET', `bibliotheque/${idb}/preferences`);
  }
}
