import { Router } from '@angular/router';
import { Component, OnInit, ChangeDetectionStrategy, Input, HostListener } from '@angular/core';
import { Livre } from 'app/_classes/livre';

@Component({
  selector: 'bs-history-card',
  templateUrl: './history-card.component.html',
  styleUrls: ['./history-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HistoryCardComponent implements OnInit {
  @Input() livre: Livre;
  @HostListener('click') click() {
    console.log('click');
    this.router.navigate(['/livre/' + this.livre.id]);
  }

  constructor(private router: Router) {}

  ngOnInit() {
    console.log('livre: ', this.livre);
  }
}
