import { PlayerService } from './_services/player.service';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlayerComponent } from './player/player.component';
import { VideoGularService } from './_services/videogular.service';

/* VIDEOGULAR */
import { VgCoreModule } from 'videogular2/core';
import { VgControlsModule } from 'videogular2/controls';
import { VgOverlayPlayModule } from 'videogular2/overlay-play';
import { VgBufferingModule } from 'videogular2/buffering';
import { VgStreamingModule } from 'videogular2/streaming';

@NgModule({
  imports: [CommonModule, VgCoreModule, VgControlsModule, VgOverlayPlayModule, VgBufferingModule, VgStreamingModule],
  declarations: [PlayerComponent],
  providers: [],
  exports: []
})
export class VideogularPlayerModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: VideogularPlayerModule,
      providers: [VideoGularService, PlayerService]
    };
  }
}
