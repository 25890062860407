import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppState } from '../../_services/appStateModels/app.state.model';
import { AppStateService } from '../../_services/appstate.service';
import { takeWhile } from 'rxjs/operators';

/**
 * # Le composant qui met en place la mise en page générale de l'application
 */
@Component({
  selector: 'bs-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {
  isAlive: boolean;
  appState: AppState;

  /** Le constructeur */
  constructor(private appStateService: AppStateService) {}

  /** Le début du cycle de vie du composant
   * * __Ne fait rien__
   */
  ngOnInit() {
    this.isAlive = true;
    this.handleAppState();
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  handleAppState() {
    this.appStateService.appState$.pipe(takeWhile(() => this.isAlive)).subscribe(data => {
      this.appState = data;
    });
  }
}
