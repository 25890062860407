import { ThemesService } from './_services/themes.service';
import { directives } from './_directives/index';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  declarations: [...directives],
  exports: [...directives],
  providers: [ThemesService]
})
export class ThemesModule {}
