import { Pipe, PipeTransform } from '@angular/core';

/**
 * # Retourne le nom d'un chapitre sans l'extension de fichier et sans les '_'
 */
@Pipe({
  name: 'chapterName'
})
export class ChapterNamePipe implements PipeTransform {
  transform(value: string, args?: any): string {
    if (value) {
      value = value.lastIndexOf('.') != -1 ? value.slice(0, value.lastIndexOf('.')) : value;
      return value.split('_').join(' ');
    }
    return null;
  }
}
