import { AppStateService } from 'app/_services/appstate.service';
import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { takeWhile, pluck } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'bs-user-log-out',
  templateUrl: './user-log-out.component.html',
  styleUrls: ['./user-log-out.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserLogOutComponent implements OnInit, OnDestroy {
  @Input() icon: string;
  @Input() label: string;
  @Input() path: string;
  isAlive = true;
  currentUser$: Observable<any>;

  constructor(private appstateService: AppStateService, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.appstateService.appState$
      .pipe(
        takeWhile(() => this.isAlive),
        pluck('contrast')
      )
      .subscribe(data => this.cd.markForCheck());

    this.currentUser$ = this.appstateService.appState$.pipe(pluck('user','currentUser'));
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
