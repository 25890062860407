import { Observable } from 'rxjs';
import { VideoGularService } from './../_services/videogular.service';
import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { VgAPI } from 'videogular2/core';
import { Stream } from '../_models/stream.model.interface';

@Component({
  selector: 'bs-vg-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss']
})
export class PlayerComponent implements OnInit, OnChanges {
  media: any;
  state$: Observable<string>;

  vgHls: any;
  position: number;
  autoplay$: Observable<boolean>;

  currentStream$: Observable<Stream>;
  playlist$: Observable<Stream[]>;

  constructor(private vgService: VideoGularService) {}

  ngOnInit() {
    this.currentStream$ = this.vgService._currentStream$;
    this.playlist$ = this.vgService._playlist$;
    this.autoplay$ = this.vgService._autoplay$;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.playlist && !changes.playlist.firstChange) {
      this.vgService.setCurrentStream(this.vgService._playlist.getValue()[0]);
    }
  }

  play() {
    this.vgService.play();
    this.vgService.setAutoplay(true);
    console.log('#### PLAY ###');
  }

  pause() {
    this.vgService.pause();
  }

  onPlayerReady(vgAPI: VgAPI) {
    // console.log('vgAPI: ', vgAPI);
    // console.log('#### VG Player Ready ###');
    this.vgService.initVgApi(vgAPI);
    this.state$ = this.vgService.state;
    this.position = Math.floor(this.vgService.position / 1000);
  }

  getSeconds(pos) {
    return Math.floor(pos / 1000);
  }

  seek50() {
    this.vgService.seekTime(50);
  }

  ff10() {
    this.vgService.ff();
  }
  rw10() {
    this.vgService.rw();
  }

  jog(event) {
    const width = event.path.find(el => el.className === 'progressbar').offsetWidth;
    const dist = event.offsetX;
    this.vgService.seekTime(((dist / width) * this.vgService.duration) / 1000);
  }

  playTrack(index: number | string) {
    this.vgService.setCurrentStream(this.vgService._playlist.getValue().find(elt => elt.id === index));
    setTimeout(() => this.play(), 1000);
    // this.play();
  }

  /* next() {
    const i = this.playlist.findIndex(el => el.id === this.vgService._currentStream.getValue().id) + 1;
    if (i < this.playlist.length) {
      this.vgService.setCurrentStream(this.playlist[i]);
    }
  }
  prev() {
    const i = this.playlist.findIndex(el => el.id === this.vgService._currentStream.getValue().id) - 1;
    if (i >= 0) {
      this.vgService.setCurrentStream(this.playlist[i]);
    }
  } */

  submitUrl(value: any) {
    // this.currentStream = { id: 4, name: 'test', url: value.url };
  }
}
