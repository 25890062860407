import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlaylistTrackItemComponent } from './_components/playlist-track-item/playlist-track-item.component';

@NgModule({
  declarations: [PlaylistTrackItemComponent],
  imports: [CommonModule],
  exports: [PlaylistTrackItemComponent]
})
export class PlaylistModule {}
