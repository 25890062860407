import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppStateService } from '../../_services/appstate.service';
import { AppState } from '../../_services/appStateModels/app.state.model';
import { takeWhile } from 'rxjs/operators';

import { style, state, animate, transition, trigger } from '@angular/animations';
import { MenuElement } from '../../_classes/menu-element';
import { MenuService } from '../../_services/menu.service';
import { Router } from '@angular/router';
import { ReglagesPlayer } from '../../_classes/reglages-player';

@Component({
  selector: 'bs-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
  animations: [
    trigger('toggle', [
      transition(':enter', [
        // :enter is alias to 'void => *'
        style({ left: '-250px', display: 'none' }),
        animate('600ms', style({ display: '*' })),
        animate('350ms ease-in', style({ left: '0' }))
      ]),
      transition(':leave', [
        // :leave is alias to '* => void'
        animate('300ms', style({ display: '*' })),
        animate('350ms ease-in', style({ left: '-250px' }))
      ])
    ]),
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('600ms ease-in', style({ opacity: '0' })),
        animate('350ms ease-in', style({ opacity: '*' }))
      ]),
      transition(':leave', animate('350ms ease-in', style({ opacity: '0' })))
    ])
  ]
})
export class MobileMenuComponent implements OnInit, OnDestroy {
  isAlive: boolean;
  appState: AppState;
  dyslexie: boolean;
  contrast: boolean;

  searching = false;

  /** La liste des durées possibles pour le réglage de la durée des sauts */
  selectRF: any[] = new ReglagesPlayer().dureeSauts;

  menu: MenuElement[];

  constructor(private appStateService: AppStateService, private menuService: MenuService, private router: Router) {}

  ngOnInit() {
    this.isAlive = true;
    this.handleAppState();
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  handleAppState() {
    this.appStateService.appState$.pipe(takeWhile(() => this.isAlive)).subscribe(data => {
      this.appState = data;
      this.menu = this.menuService.getMenuMobile();
    });
  }

  toggleDisplay() {
    this.appStateService.toggleMobileMenu();
  }

  toggleDyslexie(): void {
    this.appStateService.setIsDyslexia(this.dyslexie);
  }
  toggleContraste(): void {
    this.appStateService.setIsContrast(this.contrast);
  }

  navigate(url: string): void {
    this.appStateService.toggleMobileMenu();
    this.router.navigate([url]);
  }

  /**
   * ### Augmente la taille de la police de caractère
   */
  increaseFontSize(): void {
    this.appStateService.setFontSize(Math.round(this.appState.user.userPrefs.fontSize * 1.125));
  }

  /**
   * ### Diminue la taille de la police de caractère
   */
  decreaseFontSize(): void {
    this.appStateService.setFontSize(Math.round(this.appState.user.userPrefs.fontSize / 1.125));
  }

  /**
   * ### Ré-initialise la taille de la police de caractère
   */
  resetFontSize(): void {
    this.appStateService.setFontSize(62);
  }

  updatePlayerSettings() {
    this.appStateService.setPlayerSettings(this.appState.user.userPrefs.playerSettings);
  }

  toggleSearch(): void {
    this.searching = !this.searching;
  }
}
