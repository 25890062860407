import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BsModalService {
  _modalIsVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  _errorMessage: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor() {}

  get modalIsVisible$(): Observable<boolean> {
    return this._modalIsVisible.asObservable();
  }
  setModalIsVisible$(visible: boolean) {
    this._modalIsVisible.next(visible);
  }

  get errorMessage$(): Observable<string> {
    return this._errorMessage.asObservable();
  }

  setErrorMessage$(message: string) {
    this._errorMessage.next(message);
  }
}
