import { Directive, HostBinding, OnInit, OnDestroy, HostListener } from '@angular/core';
import { takeWhile } from 'rxjs/operators';

import { AppStateService } from 'app/_services/appstate.service';

@Directive({ selector: '[bsContrastOlFocusBlue]' })
export class ContrastOlFocusBlueDirective implements OnInit, OnDestroy {
  isAlive = true;
  isContrast = false;

  @HostBinding('style.outline-color')
  outline: string;

  @HostListener('focus')
  onFocus() {
    if (this.isContrast) {
      this.outline = '#000080';
    } else {
      this.outline = null;
    }
  }

  constructor(private appstateService: AppStateService) {}

  ngOnInit() {
    this.appstateService.appState$.pipe(takeWhile(() => this.isAlive)).subscribe(
      data => {
        this.isContrast = data.user.userPrefs.contrast;
      },
      error => {
        console.error(error);
      }
    );
  }

  ngOnDestroy() {
    this.isAlive = false;
  }
}
