import { PlaylistModule } from './../playlist/playlist.module';
import { PopinEmpruntComponent } from './_components/popin-emprunt/popin-emprunt.component';
import { RouterModule } from '@angular/router';
import { UserLogOutComponent } from './_components/user-log-out/user-log-out.component';
import { ThemesModule } from './../themes/themes.module';
import { SpinnerComponent } from './_components/spinner/spinner.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from './../pipes/pipes.module';

import { LightboxComponent } from './_components/lightbox/lightbox.component';
import { PopinComponent } from './_components/popin/popin.component';
import { SwitchComponent } from './_components/switch/switch.component';
import { SliderComponent } from './_components/slider/slider.component';
import { PlayerMobileComponent } from './_components/player-mobile/player-mobile.component';
import { PlayerExtraitComponent } from './_components/player-extrait/player-extrait.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, PipesModule, ThemesModule, RouterModule, PlaylistModule],
  declarations: [
    LightboxComponent,
    PopinComponent,
    PopinEmpruntComponent,
    SwitchComponent,
    SliderComponent,
    PlayerExtraitComponent,
    PlayerMobileComponent,
    SpinnerComponent,
    UserLogOutComponent
  ],
  exports: [
    LightboxComponent,
    PopinComponent,
    PopinEmpruntComponent,
    SwitchComponent,
    SliderComponent,
    PopinComponent,
    PlayerExtraitComponent,
    PlayerMobileComponent,
    SpinnerComponent,
    UserLogOutComponent
  ],
  providers: []
})
export class BsCommonModule {}
