import { AppState } from './_services/appStateModels/app.state.model';
import { AppStateService } from './_services/appstate.service';
import { BsModalService } from './_modules/bs-modal/bs-modal.service';
import { takeWhile } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { VideoGularService } from './_modules/videogular-player/_services/videogular.service';
import { VgAPI } from 'videogular2/core';
import { Stream } from './_modules/videogular-player/_models/stream.model.interface';
import { Observable } from 'rxjs';

declare var jquery: any;
declare var $: any;

/**
 * # Le composant principal de l'application
 */
@Component({
  selector: 'bs-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  /** Le nom de l'application */
  title = 'Bibliostream';
  vgHls: any;
  media: any;
  isAlive = true;
  appState: AppState;

  currentStream$: Observable<Stream>;
  autoplay$: Observable<boolean>;
  errorMessage$: Observable<string>;

  constructor(
    private vgService: VideoGularService,
    private appStateService: AppStateService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.errorMessage$ = this.modalService.errorMessage$;
    this.modalService.setErrorMessage$(`Vous n'êtes pas connecté, vous allez être redirigé vers la page de connexion`);
    this.currentStream$ = this.vgService._currentStream$;
    this.autoplay$ = this.vgService._autoplay$;
    this.appStateService.getBibPrefs();
    this.handleAppState();
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  onPlayerReady(vgAPI: VgAPI) {
    // console.log('vgAPI: ', vgAPI);
    // console.log('#### VG Player Ready ###');
    this.vgService.initVgApi(vgAPI);
  }

  handleAppState() {
    this.appStateService.appState$.pipe(takeWhile(() => this.isAlive)).subscribe((data: AppState) => {
      this.appState = data;
      this.vgService.rwffSaut = +data.user.userPrefs.playerSettings.rewindForward;
    });
  }

  closeModal() {
    this.modalService.setModalIsVisible$(false);
  }
}
