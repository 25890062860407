import {RequestService} from 'app/_services/request.service';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {UserService} from './user.service';
import {PlayerService} from 'app/_modules/videogular-player/_services/player.service';
import {BibliothequeService} from './bibliotheque.service';
import {BsModalService} from 'app/_modules/bs-modal/bs-modal.service';
import * as fromStateModels from './appStateModels';
import {User} from 'app/_interfaces/user';
import {Livre} from 'app/_classes/livre';
import {switchMap, takeWhile, tap} from 'rxjs/operators';
import {Stream} from 'app/_modules/videogular-player/_models/stream.model.interface';

@Injectable()
export class AppStateService {
  /** Le subject de l'état */
  private _appState: BehaviorSubject<fromStateModels.AppState>;
  /** Une sauvegarde de l'état */
  private appState: fromStateModels.AppState;
  /** Détermine l'état des subscribes RXJS */
  isAlive = true;
  /**
   * Le thème par défaut,
   * * À récupérer dans les préférences de la bibliothèque
   */
  defaultTheme = 'light';

  constructor(
    private requestService: RequestService,
    private userService: UserService,
    private playerService: PlayerService,
    private bibService: BibliothequeService,
    private modalService: BsModalService
  ) {
    let initialState = {};
    /** Initialisation des preférences de la bibliothèque */
    const initialBibPrefsState: fromStateModels.BibPrefsState = {
      themeName: this.defaultTheme,
      theme: {
        'color-accent': '#4dcaf3',
        'color-secondary': '#04be5b',
        'color-text': '#000000',
        'color-text-light': '#bababa',
        'color-background': '#f7f7f7'
      },
      baseline: '',
      logo: ''
    };

    /** Initialisation des info relative à l'utilisateur */
    const initialUserState: fromStateModels.UserState = {
      currentUser: null,
      userPrefs: {
        contrast: false,
        dyslexia: false,
        fontSize: 75,
        playerSettings: {rewindForward: 30, numberOfTracks: 5}
      }
    };

    /** Initialise les élements de l'UI affichés/masqués */
    const initialUIDisplayState: fromStateModels.UIDisplayState = {
      displayMobileMenu: false,
      displayPlayerWidget: true
    };

    /** Initialise les éléments de liste */
    const initialListeState: fromStateModels.ListeState = {
      reloadList: false,
      pagination: {limit: 25, offset: 0, filters: null},
      displayType: false
    };

    /** Initialise le livre en cours de lecture */
    const initialLivreEncoursState: fromStateModels.LivreEnCoursState = {
      playing: null,
      chapters: null,
      loadingChapters: false
    };

    /** L'etat initial de l'appli */
    const initialStates: {
      bibPrefs: fromStateModels.BibPrefsState;
      user: fromStateModels.UserState;
      uiDisplay: fromStateModels.UIDisplayState;
      liste: fromStateModels.ListeState;
      livreEnCours: fromStateModels.LivreEnCoursState;
    } = {
      bibPrefs: initialBibPrefsState,
      liste: initialListeState,
      user: initialUserState,
      uiDisplay: initialUIDisplayState,
      livreEnCours: initialLivreEncoursState,
    };

    initialState = {...initialState, ...initialStates};
    this.appState = initialState;
    this._appState = new BehaviorSubject<any>(this.appState);
    this.dispatchState();
  }

  private dispatchState() {
    this._appState.next(this.appState);
  }

  get appState$(): Observable<any> {
    return this._appState.asObservable();
  }

  /**
   * ### Permet d'activer le rechargement de la liste des livres de la bibliothèque
   */
  toggleReloadList(): void {
    this.appState = {
      ...this.appState,
      liste: {
        ...this.appState.liste,
        reloadList: this.appState.liste.reloadList ? !this.appState.liste.reloadList : true
      }
    };
    this.dispatchState();
  }

  /**
   * ### Active/désactive le mode contraste
   * @param contrast Si on est en mode contraste ou pas
   */
  setIsContrast(contrast: boolean): void {
    this.appState = {
      ...this.appState,
      user: {...this.appState.user, userPrefs: {...this.appState.user.userPrefs, contrast: contrast}}
    };

    if (contrast) {
      this.handleThemeName('contrast');
    } else {
      this.handleThemeName(this.defaultTheme);
    }
    this.changeUserPrefs();
    this.dispatchState();
  }

  /**
   * ### Active/désactive le mode contraste
   * @param contrast Si on est en mode contraste ou pas
   */
  toggleIsContrast(): void {
    this.appState = {
      ...this.appState,
      user: {
        ...this.appState.user,
        userPrefs: {...this.appState.user.userPrefs, contrast: !this.appState.user.userPrefs.contrast}
      }
    };
    if (this.appState.user.userPrefs.contrast) {
      this.handleThemeName('contrast');
    } else {
      this.handleThemeName(this.defaultTheme);
    }
    this.changeUserPrefs();
  }

  /**
   * ### Permet de définir le thème actif
   * @param name Le nom du thème
   */
  handleThemeName(name: string) {
    const bbp: fromStateModels.BibPrefsState = {...this.appState.bibPrefs, themeName: name};
    this.appState = {...this.appState, bibPrefs: bbp};
    this.dispatchState();
  }

  /**
   * ### Enregistre les préférences d'affichage et du lecteur audio dans les préférence de l'utilisateur (API)
   */
  changeUserPrefs(): void {
    if (this.appState.user.currentUser) {
      const userPrefs = {
        dislexie: this.appState.user.userPrefs.dyslexia,
        contraste: this.appState.user.userPrefs.contrast,
        tailleTypo: this.appState.user.userPrefs.fontSize,
        plLongueurSaut: this.appState.user.userPrefs.playerSettings.rewindForward,
        plNbPlaylist: this.appState.user.userPrefs.playerSettings.numberOfTracks
      };
      this.requestService
        .makeRequest('PATCH', `user/${this.appState.user.currentUser.id}/preferences`, userPrefs)
        .subscribe(
          data => data,
          error => error,
          () => {
            this.dispatchState();
          }
        );
    }
  }

  /**
   * ### Récupère les préférences du user
   */
  getUserPrefs() {
    if (this.appState.user.currentUser) {
      this.userService.getUserPreference(this.appState.user.currentUser.id).subscribe(
        data => {
          this.appState.user.userPrefs.contrast = data && data.contraste ? data.contraste : false;
          this.setIsContrast(this.appState.user.userPrefs.contrast);
          this.appState.user.userPrefs.dyslexia = data && data.dislexie ? data.dislexie : false;
          this.appState.user.userPrefs.fontSize = data && data.tailleTypo ? data.tailleTypo : 75;
          this.appState.user.userPrefs.playerSettings = {
            rewindForward: data && data.plLongueurSaut ? data.plLongueurSaut : 30,
            numberOfTracks: data && data.plNbPlaylist ? data.plNbPlaylist : '5'
          };
        },
        error => error,
        () => {
          this.dispatchState();
        }
      );
    }
  }

  /**
   * ### Active/désactive le mode dyslexie (police spéciale)
   * @param dyslexia Si on est en mode dyslexie
   */
  setIsDyslexia(dyslexia: boolean): void {
    this.appState.user.userPrefs.dyslexia = dyslexia;
    this.changeUserPrefs();
  }

  /**
   * ### Définit la taille de la police de caractères
   * @param size La taille de la police de caractères (%)
   */
  setFontSize(size: number): void {
    this.appState.user.userPrefs.fontSize = size;
    this.changeUserPrefs();
  }

  /**
   * ### Définit les réglages du lecteur audio
   * @param settings Les réglages à enregistrer
   */
  setPlayerSettings(settings: { rewindForward: number; numberOfTracks: string | number }): void {
    this.appState.user.userPrefs.playerSettings = settings;
    this.changeUserPrefs();
  }

  /**
   * ### Définit un utilisateur comme utilisateur courant
   * @param user L'utilisateur
   */
  setCurrentUser(user: User): void {
    this.appState.user.currentUser = user;
    this.dispatchState();
  }

  /**
   * ### Ré-initisalise l'aspect visuel de l'appli
   */
  resetLook(): void {
    this.appState.bibPrefs.themeName = this.defaultTheme;
    this.appState.user.userPrefs.fontSize = 75;
    this.appState.user.userPrefs.dyslexia = false;
    this.appState.user.currentUser = null;
    this.dispatchState();
  }

  /**
   * ### Lance la lecture audio d'un livre
   * @param livre Le livre à écouter
   */
  playBook(livre: Livre): void {
    this.appState.livreEnCours.playing = livre;
    this.setLoadingChapters(true);
    this.playerService.setPlaylist(null);
    if (livre) {
      this.playerService.getBookChapters(livre.id).subscribe(
        (data: any) => {
          if (data.error && data.error === 'Durée de prêt dépassé') {
            this.modalService.setErrorMessage$(
              `L'emprunt de ce livre est arrivé à échéance, vous ne pouvez plus le consulter...`
            );
            this.modalService.setModalIsVisible$(true);
            this.resetPlayBook();
          } else {
            this.playerService.makePlayListLivre(livre.id, data);
          }
        },
        error => console.error(error)
      );
    }
  }

  /**
   * ### Change l'état de chargement des chapitres
   */
  toggleLoadingChapters() {
    this.appState = {
      ...this.appState,
      livreEnCours: {...this.appState.livreEnCours, loadingChapters: !this.appState.livreEnCours.loadingChapters}
    };
    this.dispatchState();
  }

  /**
   * ### Définit l'état de chargement des chapitres
   */
  setLoadingChapters(loading: boolean) {
    this.appState = {
      ...this.appState,
      livreEnCours: {...this.appState.livreEnCours, loadingChapters: loading}
    };
    this.dispatchState();
  }

  /**
   * ### Définit la liste des chapitres
   * @param chapters la playlist du livre
   */
  setLivreEnCoursChapters(chapters: Stream[]) {
    this.appState = {...this.appState, livreEnCours: {...this.appState.livreEnCours, chapters: chapters}};
    this.dispatchState();
  }

  /**
   * ### Reset des élements relatifs à la lecture d'un livre.
   */
  resetPlayBook() {
    this.appState.livreEnCours = {playing: null, chapters: null, loadingChapters: false};
    this.playerService.setPlaylist(null);
    this.toggleReloadList();
  }

  /**
   * ### Active/désactive l'affichage de la liste des livres de la bibliothèque sous forme de liste (sinon => grille)
   */
  toggleDisplayList(): void {
    this.appState.liste.displayType = !this.appState.liste.displayType;
    this.dispatchState();
  }

  /**
   * ### Définit le type d'affichage de la liste des livres de la bibliothèque (Grille/liste)
   * @param value Liste/grille
   */
  setDisplayList(value: boolean) {
    this.appState.liste.displayType = value;
    this.dispatchState();
  }

  /**
   * ### Définit le nombre de livres à afficher par page
   * @param limit Le nombre de livres par page
   */
  changePagination(limit: number) {
    this.appState.liste.pagination.limit = Number(limit);
    this.appState.liste.reloadList = true;
    this.dispatchState();
  }

  /**
   * ### Passe à la page précédente
   */
  decPage(): void {
    if (this.appState.liste.pagination.offset > 0) {
      this.appState.liste.pagination.offset -= this.appState.liste.pagination.limit;
      this.appState.liste.reloadList = true;
      this.dispatchState();
    }
  }

  /**
   * ### Passe à la page suivante
   */
  incPage(): void {
    this.appState.liste.pagination.offset += this.appState.liste.pagination.limit;
    this.appState.liste.reloadList = true;
    this.dispatchState();
  }

  /**
   * ### Affiche/masque le menu sur mobile
   */
  toggleMobileMenu(): void {
    this.appState.uiDisplay.displayMobileMenu = !this.appState.uiDisplay.displayMobileMenu;
    this.dispatchState();
  }

  showHidePlayerWidget(show: boolean): void {
    this.appState.uiDisplay.displayPlayerWidget = show;
    this.dispatchState();
  }

  /**
   * ### Obtient les préférences choisies par la bibliothèque
   */
  getBibPrefs() {
    const bibprefs: fromStateModels.BibPrefsState = {...this.appState.bibPrefs, theme: null, baseline: '', logo: ''};

    // Obtenir l'id de la bib
    const bibprefs$ = this.userService.getUserInfo().pipe(
      switchMap(user => {
        this.setCurrentUser(user);
        this.getUserPrefs();
        return this.bibService.getBibPrefs(user.bibliotheque.id);
      })
    );
    // Obtenir les prefs
    bibprefs$.subscribe(
      data => {
        bibprefs.baseline = data.baseline;
        bibprefs.logo = data.logo ? data.logo : '';
        bibprefs.theme = {
          'color-accent': data.accent ? data.accent : '#4dcaf3',
          'color-secondary': '#04be5b',
          'color-text': '#000000',
          'color-text-light': '#bababa',
          'color-background': '#f7f7f7'
        };
        this.appState.bibPrefs = bibprefs;
        this.dispatchState();
      },
      error => console.error(error),
      () => {
      }
    );
  }

  /**
   * ### Obtient la playlist depuis le playerService
   */
  getPlayerPlaylist() {
    this.playerService.playlistLivre$
      .pipe(
        takeWhile(() => this.isAlive),
        tap(data => {
          if (data && data.length > 0) {
            this.appState.livreEnCours = {...this.appState.livreEnCours, chapters: data, loadingChapters: false};
            this.dispatchState();
          }
        })
      )
      .subscribe();
  }

  /**
   * ### Met fin aux subscribes
   */
  dispose(): void {
    this.isAlive = false;
  }
}
