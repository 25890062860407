import { CardsModule } from './../cards/cards.module';
import { BsCommonModule } from './../bs-common/bs-common.module';
import { ThemesModule } from './../themes/themes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HistoriqueComponent } from './historique/historique.component';

@NgModule({
  declarations: [HistoriqueComponent],
  imports: [BsCommonModule, CardsModule, CommonModule, ThemesModule],
  exports: [HistoriqueComponent]
})
export class HistoriqueModule {}
