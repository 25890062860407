import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppStateService } from '../../_services/appstate.service';
import { takeWhile } from 'rxjs/operators';

import { FormControl } from '@angular/forms/src/model';
import { AppState } from '../../_services/appStateModels';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Router, ActivatedRoute } from '@angular/router';

import { AuthenticationService } from '../../_services/authentication.service';
import { environment } from 'environments/environment';

/**
 * # Le composant de connexion de l'utilisateur
 */
@Component({
  selector: 'bs-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  /** Determine l'état des subscribes */
  isAlive: boolean;
  /** L'état de l'application */
  appState: AppState;
  /** La class de la balise HTML body (active le mode dyslexie) */
  bodyClassName: string;
  /** Le formulaire de connexion */
  loginForm: FormGroup;
  /** L'identifiant utilisateur */
  username: AbstractControl;
  /** Le mot de passe */
  password: AbstractControl;
  /** L'url mémorisée pour y retourner une fois connecté */
  returnUrl: string;
  /** Permet d'afficher ou non le cartouche d'erreur */
  hasError: boolean;
  /** Le message d'erreur */
  errorMessage: string;

  version: string;

  /**
   * ### Le constructeur
   * @param appstateService Le service de gestion de l'état de l'application
   * @param loginFB Le FormBuilder du formulaire
   * @param authenticationService Le service d'authentification (permet d'obtenir le token)
   * @param router Le routeur
   * @param activatedRoute La route active (pour récupérer le [returnUrl](#returnUrl))
   */
  constructor(
    private appstateService: AppStateService,
    private loginFB: FormBuilder,
    private authenticationService: AuthenticationService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.loginForm = this.loginFB.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });

    this.username = this.loginForm.controls['username'];
    this.password = this.loginForm.controls['password'];
  }

  /**
   * ### Le début du cycle de vie du composant
   */
  ngOnInit() {
    this.version = environment.version;
    this.hasError = false;
    this.isAlive = true;
    this.appstateService.resetLook();
    this.appstateService.appState$.pipe(takeWhile(() => this.isAlive)).subscribe(data => {
      this.updateAppState(data);
      this.setLook();
    });
    this.authenticationService.logout();
    this.activatedRoute.queryParamMap.pipe(takeWhile(() => this.isAlive)).subscribe(params => {
      this.returnUrl = params.get('returnUrl');
      /* if (this.returnUrl)
          this.handleError({ _body: 'Utilisateur non loggé', status: 666 }); */
    });
  }

  /**
   * ### La fin du cycle de vie du composant
   */
  ngOnDestroy() {
    this.isAlive = false;
  }

  /**
   * Enregistre l'état de l'application
   * @param data L'AppState
   */
  updateAppState(data: AppState) {
    this.appState = data;
    this.setLook();
  }

  /**
   * ### Définit les options d'affichage
   */
  setLook() {
    if (this.appState.user.userPrefs.dyslexia && this.appState.user.userPrefs.contrast) {
      this.bodyClassName = `cookiebanner dyslexia theme-${this.appState.bibPrefs.themeName}`;
    } else if (this.appState.user.userPrefs.dyslexia && !this.appState.user.userPrefs.contrast) {
      this.bodyClassName = `cookiebanner dyslexia theme-${this.appState.bibPrefs.themeName}`;
    } else if (!this.appState.user.userPrefs.dyslexia && this.appState.user.userPrefs.contrast) {
      this.bodyClassName = `cookiebanner theme-${this.appState.bibPrefs.themeName}`;
    } else {
      this.bodyClassName = `cookiebanner  theme-${this.appState.bibPrefs.themeName}`;
    }

    document.body.className = this.bodyClassName;

    this.setFontSize();
  }

  /**
   * ### Règle la taille des polices de caractère
   */
  setFontSize(): void {
    document.documentElement.setAttribute('style', `font-size:${this.appState.user.userPrefs.fontSize}%`);
  }

  /**
   * ### Gère la connexion au service
   */
  login(): void {
    this.authenticationService
      .login(this.username.value, this.password.value)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        data => {
          this.saveDataToLocalStorage(data);
          this.appstateService.setCurrentUser(data.user);
          if (this.returnUrl) {
            this.router.navigate([this.returnUrl]);
          } else {
            this.router.navigate(['/']);
          }
        },
        error => this.handleError(error)
      );
  }

  /**
   * ### Gère les erreurs de connexion
   * @param error L'erreur à gérer
   */
  handleError(error: any): void {
    this.hasError = true;
    if (error.status === 666) {
      // tslint:disable-next-line:quotemark
      this.errorMessage = "Vous n'êtes pas connecté ou vous avez été déconnecté. Veuillez vous connecter";
    } else if (error.status === 400 && error.error.message.indexOf('Invalid credentials') !== -1) {
      this.errorMessage = 'Identifiant ou mot de passe invalide';
    }
    console.log(error);
  }

  /**
   * ### Sauvegarde les données reçues (token et utilisateur) dans le LocalStorage
   * @param data Les données à sauvegarder
   */
  saveDataToLocalStorage(data: any) {
    localStorage.setItem('bibliostreamUser', JSON.stringify(data));
  }
}
