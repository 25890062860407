import { SmallCardComponent } from './_components/small-card/small-card.component';
import { ThemesModule } from './../themes/themes.module';
import { CardComponent } from './_components/card/card.component';
import { BsCommonModule } from './../bs-common/bs-common.module';
import { RouterModule } from '@angular/router';
import { ListCardComponent } from './_components/list-card/list-card.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HistoryCardComponent } from './_components/history-card/history-card.component';

@NgModule({
  declarations: [CardComponent, ListCardComponent, SmallCardComponent, HistoryCardComponent],
  imports: [BsCommonModule, CommonModule, RouterModule, ThemesModule],
  exports: [CardComponent, ListCardComponent, SmallCardComponent, HistoryCardComponent]
})
export class CardsModule {}
