import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { style, state, animate, transition, trigger } from '@angular/animations';

@Component({
  selector: 'bs-popin',
  templateUrl: './popin.component.html',
  styleUrls: ['./popin.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [style({ opacity: 0 }), animate('150ms ease-in', style({ opacity: 1 }))]),
      transition(':leave', animate('150ms ease-in', style({ opacity: 0 })))
    ])
  ]
})
export class PopinComponent implements OnInit {
  @Input() header: string;
  @Input() footer: string;
  @Input() visible: boolean;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {}

  hideDialog() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }
}
