import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'bsJumpTime' })
export class BSJumpTimePipe implements PipeTransform {
  transform(value: number): string {
    let result = '';
    if (value >= 60) {
      result = `${value / 60}'`;
    } else {
      result = `${value}"`;
    }
    return result;
  }
}
