import {AppStateService} from 'app/_services/appstate.service';
import {Router, RouterEvent, NavigationEnd} from '@angular/router';
import {NavSwitchItem} from '../nav-switch.model';
import {Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {takeWhile, pluck} from 'rxjs/operators';

@Component({
  selector: 'bs-nav-switch',
  templateUrl: './nav-switch.component.html',
  styleUrls: ['./nav-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavSwitchComponent implements OnInit, OnDestroy {
  @Input() navMenu: NavSwitchItem[] = [
    {label: 'default1', path: '/', count: 0},
    {label: 'default2', path: '/', count: 0}
  ];
  state = false;
  isAlive = true;

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private appStateService: AppStateService,
  ) {

  }

  ngOnInit() {
    this.state = this.router.url !== this.navMenu[0].path;
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        this.state =
          event.urlAfterRedirects !== this.navMenu[0].path && event.urlAfterRedirects === this.navMenu[1].path;
        this.cd.markForCheck();
      }
    });

    this.handleAppState();
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  changeState() {
    this.router.navigate(this.state ? [this.navMenu[1].path] : [this.navMenu[0].path]);
  }

  handleAppState() {
    this.appStateService.appState$
      .pipe(
        takeWhile(() => this.isAlive),
        pluck('contrast')
      )
      .subscribe(data => {

        this.cd.markForCheck();
      });
  }
}
