import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { takeWhile } from 'rxjs/operators';

import { AppStateService } from '../../_services/appstate.service';
import { AppState } from '../../_services/appStateModels/app.state.model';
import { Livre } from 'app/_classes/livre';

/**
 * # Composant d'affichage de la liste des livres de la bibliothèque.
 * Gère:
 * * Le mode d'affichage liste/grille
 * * L'affichage de la liste sur les petits écrans
 */
@Component({
  selector: 'bs-liste-livres',
  templateUrl: './liste-livres.component.html',
  styleUrls: ['./liste-livres.component.scss']
})
export class ListeLivresComponent implements OnInit, OnDestroy {
  /** La liste de livre à afficher */
  @Input()
  listeLivres: Livre[];
  /** Comment on affiche la liste: liste horizontale? grille? */
  @Input()
  displayList: boolean;

  /** Détermine l'état des subscribes */
  isAlive: boolean;
  /** l'état de l'application */
  appState: AppState;
  show = 40;

  /** ### Le constructeur
   * @param appstateService Le service de gestion de l'état de l'application
   */
  constructor(private appStateService: AppStateService) {}

  /** ### Initialisation du composant
   * * Initialise les subscribes
   */
  ngOnInit() {
    this.isAlive = true;
  }

  getData() {
    this.show += 20;
  }

  /** ### Fin du cycle de vie du composant
   * * ferme les subscribes
   */
  ngOnDestroy() {
    this.isAlive = false;
  }

  /** ### Récupère l'état de l'application et s'abonne */
  handleAppState(): void {
    this.appStateService.appState$.pipe(takeWhile(() => this.isAlive)).subscribe(data => (this.appState = data));
  }
}
