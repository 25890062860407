import { AppState } from 'app/_services/appStateModels/app.state.model';
import { AppStateService } from 'app/_services/appstate.service';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'bs-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit, OnChanges {
  @Input() value: number;
  @Output() valueChangeEvent: EventEmitter<number> = new EventEmitter<number>();
  @Input() range: { min: number; max: number };
  @Input('default') defaultValue: number;
  /** La position du curseur en %*/
  position: number;
  /** Si l'utilisateur est en train de clicker+glisser la souris */
  isDragging: boolean = false;

  canSetFont: boolean = true;

  initialValue: number;

  appState$: Observable<AppState>;

  constructor(private appStateService: AppStateService) {}

  /**
   * ### Début du cycle de vie du composant
   *
   */
  ngOnInit() {
    this.setInitPosition();
    this.initialValue = this.value;
    this.appState$ = this.appStateService.appState$;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.setInitPosition();
    }
  }

  setInitPosition(): void {
    this.position = (this.value / (this.range.max - this.range.min)) * 100 - this.range.min;
  }

  setPosition(event): void {
    let largeur = 0,
      posClick = 0,
      pourcentage = 0;
    largeur = event.target.parentElement.clientWidth;
    posClick = event.layerX;
    pourcentage = posClick / largeur;
    let newSize = this.range.min + pourcentage * (this.range.max - this.range.min);
    setTimeout(() => {
      if (this.canSetFont) {
        this.valueChangeEvent.emit(newSize);
      }
    }, 200);
  }

  resetFontSize(): void {
    this.canSetFont = false;

    setTimeout(() => {
      if (this.defaultValue) {
        this.valueChangeEvent.emit(this.defaultValue);
      } else {
        this.valueChangeEvent.emit(this.initialValue);
      }
      this.canSetFont = true;
    }, 250);
  }

  startDrag(event): void {
    this.isDragging = true;
  }

  endDrag(event: any): void {
    if (this.isDragging) {
      let largeur = 0,
        posClick = 0,
        pourcentage = 0;
      largeur = event.target.parentElement.clientWidth;
      posClick = event.layerX;
      pourcentage = posClick / largeur;
      const newSize = this.range.min + pourcentage * (this.range.max - this.range.min);
      this.isDragging = false;
      setTimeout(() => {
        this.valueChangeEvent.emit(newSize);
      }, 200);
    }
  }

  drag(event): void {
    if (this.isDragging) {
      let largeur = 0,
        posClick = 0,
        pourcentage = 0;
      largeur = event.target.parentElement.clientWidth;
      posClick = event.layerX;
      pourcentage = posClick / largeur;
      this.position = pourcentage * 100;
    }
  }
}
