import { Injectable } from '@angular/core';
import { MenuElement } from '../_classes/menu-element';
import { Breadcrumb } from '../_classes/breadcrumb';
import { BreadcrumbService } from './breadcrumb.service';
import { Observable } from 'rxjs';

@Injectable()
export class MenuService {
  /** Le contenu du menu */
  menu: MenuElement[];
  /** Le contenu du menu sur mobile */
  menuMobile: MenuElement[];
  /** Le breadcrumb */
  breadcrumb: Breadcrumb[];

  subscription: any;

  constructor(private breadcrumbService: BreadcrumbService) {
    this.menu = [
      { label: 'Accueil', url: '/', active: false },
      { label: 'Mes livres audio', url: '/mes-livres', active: false },
      { label: 'Paramètres', url: '/parametres', active: false }
    ];
    this.menuMobile = [
      { label: 'Accueil', url: '/', active: false },
      { label: 'Mes livres audio', url: '/mes-livres', active: false },
      { label: 'Paramètres', url: '/parametres', active: false }
    ];
    this.handleBreadcrumb();
  }

  getMenu(): MenuElement[] {
    return this.menu;
  }
  getMenuMobile(): MenuElement[] {
    return this.menuMobile;
  }

  handleBreadcrumb(): void {
    this.subscription = this.breadcrumbService.getBreadCrumb().subscribe(data => {
      this.breadcrumb = data;
      this.activeMenuItem();
    });
  }

  activeMenuItem(): void {
    this.breadcrumb.forEach(crumb => {
      if (crumb.current) {
        this.menu.forEach(element => {
          if (element.url === crumb.url) {
            element.active = true;
          } else element.active = false;
        });
        this.menuMobile.forEach(element => {
          if (element.url === crumb.url) {
            element.active = true;
          } else element.active = false;
        });
      }
    });
  }

  dispose(): void {
    this.subscription.unsubscribe();
  }
}
