import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'BSNomsPropres'
})
export class BSNomsPropresPipe implements PipeTransform {
  transform(value: any): { name: string }[] {
    if (value && typeof value === 'string') {
      let result = [];
      value
        .split(' - ')
        .forEach(name => (result = [...result, { name: `${name.trim().split(' ')[1]} ${name.trim().split(' ')[0]}` }]));
      return result;
    } else if (value) {
      value.forEach(element => {
        element.name = element.name.trim();
        const nom = element.name.split(' ')[0];
        const prenom = element.name.split(' ')[1];
        element.name = `${prenom} ${nom}`;
      });
      return value;
    }
  }
}
