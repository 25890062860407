import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { Http, Headers, Response, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

/**
 * # Le service d'authentification
 *
 * Gère :
 *
 * * Le **Login**
 *
 * * Le **Logout**
 *
 * * Le **Header** de type *'Authorization': 'X-Auth-Token* ajouté à **chaque** requête.
 */
@Injectable()
export class AuthenticationService {
  /**
   * L'URL de l'API (vient des variables d'environement)
   */
  private API_URL = environment.API_URL;
  /**
   * L'URL pour l'authentifications.
   */
  private auth_URL = this.API_URL + 'auth-tokens';
  private reset_URL = this.API_URL + 'password_reset';
  /**
   * Le constructeur
   * @param http Le client HTTP
   */
  constructor(private httpClient: HttpClient) {}

  /**
   * Connexion au service
   *
   * @param username Le login
   * @param password Le mot de passe
   */
  login(username: string, password: string): Observable<any> {
    const header = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: header };

    return this.httpClient.post(this.auth_URL, JSON.stringify({ login: username, password: password }), options);
    // .map(response => response.json());
  }

  reset(username: string): Observable<any> {
    const header = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: header };
    return this.httpClient.post(this.reset_URL, JSON.stringify({ email: username }), options);
  }

  /**
   * Déconnexion du service
   */
  logout(): void {
    /* Enlever les info de l'utilisateur du local storage */
    localStorage.removeItem('bibliostreamUser');
  }

  isLoggedIn(): boolean {
    return localStorage.getItem('bibliostreamUser') ? true : false;
  }
}
