import { AppState } from 'app/_services/appStateModels/app.state.model';
import { AppStateService } from 'app/_services/appstate.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Livre } from 'app/_classes/livre';
import { LivresService } from 'app/_services/livres.service';
import { Observable, of } from 'rxjs/';
import { PlayerService } from 'app/_modules/videogular-player/_services/player.service';
import { Position } from './../../../../_interfaces/position.interface';
import { Stream } from 'app/_modules/videogular-player/_models/stream.model.interface';
import { style, animate, transition, trigger } from '@angular/animations';
import { takeWhile, share, pluck, tap, mergeMap, withLatestFrom } from 'rxjs/operators';
import { VgStates } from 'videogular2/core';
import { VideoGularService } from 'app/_modules/videogular-player/_services/videogular.service';

/**
 * # Le lecteur audio version mobile.
 * Le lecteur apparait en superposition des vues.
 * Il a un état minimisé et des états pour l'affichage des infos du livre en cours de lecture et de la liste de lecture.
 */
@Component({
  selector: 'bs-player-mobile',
  templateUrl: './player-mobile.component.html',
  styleUrls: ['./player-mobile.component.scss'],
  animations: [
    trigger('show', [
      transition(':enter', [
        style({ opacity: '0', height: '0' }),
        animate('340ms ease-in', style({ height: '*' })),
        animate('10ms ease-in', style({ opacity: '*' }))
      ]),
      transition(':leave', [
        style({ height: '*' }),
        animate('10ms ease-in', style({ opacity: '0' })),
        animate('340ms ease-in', style({ height: '0' }))
      ])
    ])
  ]
})
export class PlayerMobileComponent implements OnInit, OnDestroy {
  /** Determine l'état des subscribes */
  isAlive: boolean;
  /** L'état de l'application */
  appState: AppState;
  /** La liste de lecture du livre en cours */
  playlistLivre$: Observable<Stream[]>;
  /** Le livre en cours de lecture */
  currentPlaying$: Observable<Stream>;
  /** Si le lecteur est en cours de lecture */
  isPlaying: boolean;
  isPlayerLoading = false;
  /** Les informations relative au fichier en cours de lecture
   * * durée
   * * position de la tête de lecture
   * * progression
   */
  trackInfo: { duration: number; position: number; progression: string } = {
    duration: 0,
    position: 0,
    progression: '0%'
  };
  /** Si on affiche la liste de lecture */
  showPlaylist = false;
  /** Si on affiche l'entête de la liste de lecture */
  showPlaylistHeader = false;
  /** Si on affiche les informations relatives au livre */
  showInfo = false;
  /** Si on affiche l'entête des informations du livre */
  showInfoHeader = false;
  /** Le livre en cours de lecture */
  livreEnCours$: Observable<Livre>;
  /** La liste des positions de tête de lecture enregistrées pour les chapitres */
  allPositions: Position[];

  /**
   *
   * @param audioPlayerService Le service de gestion du lecteur audio
   * @param appStateService Le service de gestion de l'état de l'application
   */
  constructor(
    private appStateService: AppStateService,
    public vgService: VideoGularService,
    private livreService: LivresService
  ) {}

  /**
   * ### Début du cycle de vie du composant.
   * * Active les subscribes
   */
  ngOnInit() {
    this.isAlive = true;
    this.handleAppState();
    this.getPlayerInfo();
    this.getSubscriptionStreams();
  }
  /**
   * ### Fin du cycle de vie du composant.
   * * Désactive les subscribes
   */
  ngOnDestroy() {
    this.isAlive = false;
  }

  /**
   * ### Permet de récupérer les mises à jour de l'état de l'application
   */
  handleAppState(): void {
    this.appStateService.appState$.pipe(takeWhile(() => this.isAlive)).subscribe(data => {
      this.appState = data;
    });
    this.livreEnCours$ = this.appStateService.appState$.pipe(
      pluck('livreEnCours', 'playing'),
      tap(livre => this.getAllPositions())
    );
  }

  /**
   * ### Récupère les informations relatives au lecteur audio.
   * * Livre/chapitre en cours de lecture
   * * Le statut de lecture du lecteur
   * * La liste de lecture
   * * La position de la tête de lecture
   * * La progression (% du chapitre lu)
   */
  getPlayerInfo(): void {
    this.playlistLivre$ = this.vgService._playlist$;
    this.vgService.state.pipe(takeWhile(() => this.isAlive)).subscribe(data => {
      const state = data;
      switch (state) {
        case VgStates.VG_PLAYING:
          this.isPlaying = true;
          this.isPlayerLoading = false;
          break;
        case VgStates.VG_PAUSED:
          this.isPlaying = false;
          break;
        case VgStates.VG_LOADING:
          this.isPlayerLoading = true;
          break;
      }
    });
    this.currentPlaying$ = this.vgService._currentStream$;
  }

  /**
   * ### Lecture/pause
   */
  togglePlaying(): void {
    if (!this.isPlaying) {
      this.vgService.play();
    } else {
      this.vgService.pause();
    }
  }

  /**
   * ### Chapitre précédent
   */
  previous(): void {
    this.vgService.previous();
  }

  /**
   * ### Chaptitre suivant
   */
  next(): void {
    this.vgService.next();
  }

  /**
   * Retour arrière de la valeur de saut définie dans les paramètres
   */
  rewind(): void {
    this.vgService.rw();
  }
  /**
   * Avance rapide de la valeur de saut définie dans les paramètres
   */
  fastfw(): void {
    this.vgService.ff();
  }

  /**
   * ### Lire un chapitre de la liste de lecture
   * @param chapter Le chapitre à lire
   */
  playChapter(chapter: any): void {
    this.vgService.playTrack(chapter.id);
  }

  /**
   * ### Affiche/masque les entêtes des informations/liste de lecture
   */
  togglePlayer(): void {
    this.showInfoHeader = !this.showInfoHeader;
    this.showPlaylistHeader = !this.showPlaylistHeader;
    this.showInfo = false;
    this.showPlaylist = false;
  }

  /**
   * ### Ouvre/ferme la liste de lecture
   */
  togglePlaylist(): void {
    this.showPlaylist = !this.showPlaylist;
    this.showInfo = false;
  }

  /**
   * ### ouvre/ferme les infos du livre
   */
  toggleInfo(): void {
    this.showInfo = !this.showInfo;
    this.showPlaylist = false;
  }

  getOpacity(): number {
    return this.appState.user.userPrefs.contrast ? 0 : 0.15;
  }

  getAllPositions() {
    this.livreService
      .getAllPositions(this.appState.user.currentUser.id, this.appState.livreEnCours.playing.id)
      .subscribe(pos => {
        this.allPositions = pos;
        // console.log('%cthis.allPositions: ', 'color:#4527a0', this.allPositions);
        if (this.allPositions.length === 0) {
          this.appState.livreEnCours.chapters.forEach(element => {
            this.allPositions = [
              ...this.allPositions,
              <Position>{ chapitre: element.title + '.m3u8', position: 0, position_max: 0 }
            ];
          });
          // console.log('%cthis.allPositions: ', 'color:#1b5e20', this.allPositions);
        }
      });
  }

  getSubscriptionStreams() {
    /* this.vgService.subscriptionStream.ended.subscribe(data => {
      let prev = this.appState.livreEnCours.chapters.findIndex(stream => stream.id === this.currentPlaying$.id);
      prev = prev === this.appState.livreEnCours.chapters.length - 1 ? prev : prev - 1;
      this.allPositions[
        this.allPositions.findIndex(pos => pos.chapitre === this.appState.livreEnCours.chapters[prev].title + '.m3u8')
      ].position_max = 1;
    }); */

    this.vgService.subscriptionStream.ended
      .pipe(
        takeWhile(() => this.isAlive),
        withLatestFrom(this.appStateService.appState$.pipe(pluck('livreEnCours')), this.currentPlaying$),
        mergeMap(([data, livreEncours, currentPlaying], index) => {
          let prev = livreEncours.chapters.findIndex(stream => stream.id === currentPlaying.id);
          prev = prev === livreEncours.chapters.length - 1 ? prev : prev - 1;
          this.allPositions[
            this.allPositions.findIndex(pos => pos.chapitre === livreEncours.chapters[prev].title + '.m3u8')
          ].position_max = 1;
          return of(null);
        })
      )
      .subscribe();
  }
}
