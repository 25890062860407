import {AppStateService} from '../../_services/appstate.service';
import {BSNomsPropresPipe} from './../../_modules/pipes/_pipes';
import {Component, OnInit, OnDestroy} from '@angular/core';
import {LivresService} from '../../_services/livres.service';
import {UserService} from '../../_services/user.service';
import {BreadcrumbService} from '../../_services/breadcrumb.service';
import {takeWhile} from 'rxjs/operators';

import {Livre} from '../../_classes/livre';
import {User} from '../../_interfaces/user';
import {Breadcrumb} from '../../_classes/breadcrumb';

import {AppState} from '../../_services/appStateModels/app.state.model';

enum Tri {
  AUTEUR = 'auteur',
  TITRE = 'titre',
  DISPO = 'disponibilité'
}

/**
 * # L'accueil du site après connexion
 * *Affiche la bibliothèque
 */
@Component({
  selector: 'bs-accueil',
  templateUrl: './accueil.component.html',
  styleUrls: ['./accueil.component.scss']
})
export class AccueilComponent implements OnInit, OnDestroy {
  /** Les livres disponibles dans la bibliothèque */
  livresDispo: Livre[] = [];
  /** L'utilisateur */
  currentUser: User;
  /** L'état de l'application */
  appState: AppState;
  /** Determine l'état des subscribes */
  isAlive: boolean;
  /** La liste des tri possibles */
  sortList: any[] = [{id: 1, label: Tri.TITRE}, {id: 2, label: Tri.DISPO}];
  /** L'ordre de tri choisi */
  sortBy = Tri.TITRE;
  /** Determine le type d'affichage de la liste: liste ou grille */
  displayList = false;
  /** Les termes du champ de recherche */
  searchTerms: string;
  /** Définit les options du nombre d'éléments par page pour la pagination */
  pagination: number[] = [10, 25, 50];
  /** Nombre de livre affiché par page */
  nbrPP: any;
  /** Si la liste des livres est en cours de chargement */
  isLoading = true;

  /**
   * ### Le constructeur
   * @param livresService Le service de gestion des livres
   * @param userService
   * @param breadcrumbService
   * @param appstateService
   * @param namePipe
   */
  constructor(
    private livresService: LivresService,
    private userService: UserService,
    private breadcrumbService: BreadcrumbService,
    private appstateService: AppStateService,
    private namePipe: BSNomsPropresPipe
  ) {
  }

  /** ### Initialisation du composant */
  ngOnInit() {
    this.isAlive = true;
    this.setBreadCrumb();
    this.handleAppState();
    // this.appstateService.getBibPrefs();
  }

  /**
   * ### La fin du cycle de vie du composant
   * Met fin aux subscribes
   */
  ngOnDestroy() {
    this.isAlive = false;
  }

  /**
   * ### Gestion de l'état de l'application.
   * * Si l'état n'est pas définit dans le composant, on va le cercher
   * * On s'abonne au Subject
   */
  handleAppState(): void {
    this.appstateService.toggleReloadList();
    this.appstateService.appState$.pipe(takeWhile(() => this.isAlive)).subscribe(data => {
      this.appState = data;
      if (this.appState.liste.reloadList) {
        this.livresDispo = [];
        this.getBibCatalog(
          this.appState.liste.pagination.filters,
          this.appState.liste.pagination.limit,
          this.appState.liste.pagination.offset
        );
        this.nbrPP = this.appState.liste.pagination.limit;
        this.appstateService.toggleReloadList();
      }
      this.displayList = this.appState.liste.displayType;
    });
  }

  /**
   * ### Obtient le catalogue de la bibliothèque
   * En fonction :
   * * De filtres pour la recherche et, à l'avenir, l'ordre
   * * Du nombre de livres à afficher par page
   * * De la page affichée
   */
  getBibCatalog(filters?: { key: string; value: string }[], limit?: number, offset?: number): void {
    let liste: any[];
    this.livresDispo = [];
    this.livresService
      .getBibCatalog(filters ? filters : undefined, limit, offset)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        data => {
          liste = data;
        },
        error => console.error(error),
        () => {
          this.isLoading = false;
          this.livresDispo = liste;
          this.sortListBy(this.sortBy);
        }
      );
  }

  /**
   * ### Définit le breadcrumb
   */
  setBreadCrumb(): void {
    this.breadcrumbService.setBreadCrumb([new Breadcrumb('Accueil', '', true)]);
  }

  /**
   * ### Change l'ordre d'affichage (tri) des livres
   * @param param 1:auteur 2:titre
   */
  sortListBy(param: string): void {
    switch (param) {
      case Tri.AUTEUR: {
        this.livresDispo = this.livresDispo.sort((a, b) => {
          return this.getAuthorName(a.auteurs[0].name) < this.getAuthorName(b.auteurs[0].name)
            ? -1
            : this.getAuthorName(a.auteurs[0].name) > this.getAuthorName(b.auteurs[0].name)
              ? 1
              : 0;
        });
        break;
      }
      case Tri.TITRE: {
        this.livresDispo = this.livresDispo.sort((a, b) => {
          return a.title < b.title ? -1 : a.title > b.title ? 1 : 0;
        });
        break;
      }
      case Tri.DISPO: {
        /* Tri:
        1/ livres Empruntés (ordre alpha)
        2/ livres dispo (ordre alpha)
        3/ livres bientot dispo (ordre alpha)
        */
        let empruntes: Livre[], dispo: Livre[], notDispo: Livre[];

        empruntes = this.livresDispo
          .filter(livre => livre.dejaEmprunte)
          .sort((a, b) => {
            return a.title < b.title ? -1 : a.title > b.title ? 1 : 0;
          });

        dispo = this.livresDispo
          .filter(livre => !livre.dejaEmprunte && livre.empruntable)
          .sort((a, b) => {
            return a.title < b.title ? -1 : a.title > b.title ? 1 : 0;
          });

        notDispo = this.livresDispo
          .filter(livre => !livre.dejaEmprunte && !livre.empruntable)
          .sort((a, b) => {
            return a.title < b.title ? -1 : a.title > b.title ? 1 : 0;
          });

        this.livresDispo = [...empruntes, ...dispo, ...notDispo];
        break;
      }
    }
  }

  getAuthorName(author: string): string {
    return author.split(' ')[1] ? author.split(' ')[1] : author;
  }

  /**
   * ### Fonction de recherche de livres
   */
  search() {
    this.getBibCatalog(
      [{key: 'title', value: this.searchTerms}],
      this.appState.liste.pagination.limit,
      this.appState.liste.pagination.offset
    );
  }

  /**
   * ### Évite de lancer trop de requêtes de recherche
   * @param value La valeur saisie
   */
  debounce(value: string) {
    setTimeout(() => {
      if (value === this.searchTerms) {
        this.search();
      }
    }, 400);
  }

  /**
   * Si on affiche la liste en mode liste (ou grille)
   * @param value
   */
  setDisplayList(value: boolean): void {
    this.appstateService.setDisplayList(value);
  }

  /**
   * ### Change le nombre de livres affichés par page.
   */
  changePagination(): void {
    this.appstateService.changePagination(this.nbrPP);
  }

  /**
   * ### Affiche la page précédente
   */
  decPage(): void {
    this.appstateService.decPage();
  }

  /**
   * ### Affiche la page suivante
   */
  incPage(): void {
    this.appstateService.incPage();
  }

}
